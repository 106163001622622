import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Toaster } from 'react-hot-toast';

import RouterContainer from './../../shared/route-container/RouteContainer';
import Header from '../../shared/components/header/Header';
import Loader from './../../shared/components/loader/Loader';
import Footer from './../../shared/components/footer/Footer';
import CustomCookieConsent from '../cookie-consent/CustomCookieConsent';

const HUNGARY_SHIPPING_ZONE_ID = 1;

import { start, finishSuccess, finishError, pageSelector } from './../../store/slices/pageSlice';

import {
  start as shopCategoriesStart,
  finishSuccess as shopCategoriesSuccess,
  finishError as shopCategoriesError,
  shopCategoriesSelector,
} from './../../store/slices/shopCategoriesSlice';

import {
  start as colorStart,
  finishSuccess as colorSuccess,
  finishError as colorFinishError,
  colorsSelector,
} from './../../store/slices/colorsSlice';

import { getCart, finishError as cartError, finishSuccess as cartSuccess } from './../../store/slices/cartSlice';

import {
  start as shippingMethodStart,
  finishSuccess as shippingMethodSuccess,
  finishError as shippingMethodError,
  shippingMethodsSelector,
} from './../../store/slices/shippingMethodsSlice';

import { validate, logout, userSelector } from './../../store/slices/userSlice';

import {
  getWebshopCategories,
  getColors,
  getCartPath,
  getShippingMetods,
  getPages,
} from './../../shared/api-path-generators';

import { AppContext } from './AppContext';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const { categories, loading, error } = useSelector(shopCategoriesSelector);
  const { loading: colorLoading, error: colorError } = useSelector(colorsSelector);
  const { loading: shippingMethodsLoading } = useSelector(shippingMethodsSelector);
  const { loading: pagesLoading, error: pagesError } = useSelector(pageSelector);
  const { auth } = useSelector(userSelector);

  useLayoutEffect(() => {
    dispatch(start(null, finishSuccess, finishError, getPages(), 'GET'));
    dispatch(shopCategoriesStart(null, shopCategoriesSuccess, shopCategoriesError, getWebshopCategories(), 'GET'));
    dispatch(colorStart({}, colorSuccess, colorFinishError, getColors(), 'GET'));
    dispatch(
      shippingMethodStart(
        {},
        shippingMethodSuccess,
        shippingMethodError,
        getShippingMetods(HUNGARY_SHIPPING_ZONE_ID),
        'GET'
      )
    );
    const cartKey = localStorage.getItem('cart_key');
    cartKey && dispatch(getCart(null, cartSuccess, cartError, getCartPath(cartKey), 'GET'));

    const token = localStorage.getItem('elischer-token');
    token && dispatch(validate({ token }));
  }, [dispatch]);

  useLayoutEffect(() => {
    auth && dispatch(getCart(null, cartSuccess, cartError, getCartPath(), 'GET'));
  }, [dispatch, auth]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppContext.Provider
      value={{
        language,
      }}
    >
      <div className="h-full flex flex-col">
        {loading || colorLoading || shippingMethodsLoading || pagesLoading || categories.length === 0 ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            <Header webshopMenu={categories} onLogout={handleLogout} />
            <RouterContainer />
            <Footer />
            <Toaster />
            <CustomCookieConsent />
          </>
        )}
      </div>
    </AppContext.Provider>
  );
}

export default App;
