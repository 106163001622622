import { call, put, all, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { httpRequest } from './api-calls';

import {
  getCart,
  finishError as cartError,
  finishSuccess as cartSuccess,
  cartKeySelector,
} from './../../store/slices/cartSlice';

import { getCartPath } from './../../shared/api-path-generators';

export default function* deleteWorkerSaga(action) {
  const {
    payload,
    meta: { errorAction, url, type, toastMessage },
  } = action;

  const apiCall = httpRequest(url, type);

  try {
    const { data } = yield call(apiCall, payload);
    const { cartKey } = yield select(cartKeySelector);
    try {
      toastMessage && toast(toastMessage);
      yield put(getCart(null, cartSuccess, cartError, getCartPath(cartKey), 'GET'));
    } catch (error) {
      yield all([put(errorAction(error))]);
    }
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
