import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OrdersListItem from '../orders-list-item/OrdersListItem';

const OrdersList = ({ orders }) => {
  const { t } = useTranslation();

  const ordersLines = orders.map((order) => <OrdersListItem key={order.order_key} order={order} />);

  return (
    <div className="w-full">
      <div className="border-b text-sm hidden lg:flex flex-row font-bold">
        <div className="w-2/12 text-left">{t('order number')}</div>
        <div className="w-3/12 text-right">{t('date')}</div>
        <div className="w-3/12 text-right">{t('status')}</div>
        <div className="w-2/12 text-right">{t('sum')}</div>
        <div className="w-2/12 text-right">{t('action')}</div>
      </div>
      <div className="w-full lg:hidden border-b"></div>
      {ordersLines}
    </div>
  );
};

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OrdersList;
