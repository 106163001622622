import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: undefined,
  error: false,
};

const orderSlice = createGenericSlice({
  name: 'order',
  initialState,
  reducers: {},
});

const { actions, reducer } = orderSlice;

const orderState = (state) => state.order;

export const orderSelector = createDraftSafeSelector(orderState, (state) => ({
  order: state.data,
  loading: state.loading,
  error: state.error,
}));

export const { start, finishError, finishSuccess } = actions;

export default reducer;
