import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const WebshopCategoryLink = ({ imageSource, path }) => {
  return (
    <NavLink to={path} className="overflow-hidden">
      <img src={imageSource} className="object-center" />
    </NavLink>
  );
};

WebshopCategoryLink.propTypes = {
  imageSource: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default WebshopCategoryLink;
