import React, { useLayoutEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Loader from './../loader/Loader';

import { start, finishError, finishSuccess, orderSelector } from './../../../store/slices/orderSlice';
import { userSelector } from '../../../store/slices/userSlice';

import { postOrderPath } from './../../api-path-generators';

import { AppContext } from './../../../components/app/AppContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RedirectHandler = (props) => {
  const { language } = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const { order, loading } = useSelector(orderSelector);
  const { loading: userLoading, user } = useSelector(userSelector);

  const paymentId = query.get('paymentId');
  const orderId = localStorage.getItem('orderId');

  useLayoutEffect(() => {
    !userLoading && dispatch(start(null, finishSuccess, finishError, postOrderPath(orderId), 'GET'));
  }, [dispatch, userLoading]);

  useLayoutEffect(() => {
    if (order) {
      localStorage.removeItem('currentOrder');
      const orderPaymentId = order.meta_data.find((item) => item.key === 'paymentId');

      if (order && order.status === 'processing') {
        orderPaymentId.value === paymentId && history.push(`/${language}/shop/checkout/success`);
      }
      if (order && order.status === 'cancelled') {
        orderPaymentId.value === paymentId && history.push('/hu/shop/checkout/failed');
      }
    }
  }, [order]);

  return (
    <>
      {(loading || userLoading) && !order && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
    </>
  );
};

RedirectHandler.propTypes = {};

export default RedirectHandler;
