import React, { useState, useLayoutEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clone } from 'ramda';
import { useHistory } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import PageContainer from './../../../../shared/components/page-container/PageContainer';
import AccordionItem from '../../../../shared/components/accordion-item/AccordionItem';
import CustomerInfo from './../customer-info/CustomerInfo';
import AddressForm from './../address-form/AddressForm';
import PaymentMethod from './../../payment-method/PaymentMethod';
import OrderReview from '../order-review/OrderReview';
import OrderSummary from './../../order-summary/OrderSummary';
import ShippingMethod from './../../shipping-method/ShippingMethod';

import { cartSelector } from './../../../../store/slices/cartSlice';
import { userSelector } from './../../../../store/slices/userSlice';
import { shippingMethodsSelector } from './../../../../store/slices/shippingMethodsSlice';

import { start, finishError, finishSuccess, orderSelector } from './../../../../store/slices/orderSlice';

import { postOrderPath } from './../../../../shared/api-path-generators';

import {
  formFields,
  generateInitialValues,
  validationSchemas,
  validateBillingAddress,
  validateShippingAddress,
} from './formHelpers';

import { AppContext } from './../../../app/AppContext';

const convertToArray = (dataObject) => {
  let array = [];

  for (const [key, value] of Object.entries(dataObject)) {
    array.push({
      key,
      value,
    });
  }

  return array;
};

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const Checkout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { language } = useContext(AppContext);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [order, setOrder] = useState(undefined);
  const [orderError, setOrderError] = useState(undefined);
  const { cart } = useSelector(cartSelector);
  const { order: currentOrder, loading } = useSelector(orderSelector);
  const { user } = useSelector(userSelector);
  const { shippingMethods } = useSelector(shippingMethodsSelector);

  const translatedValidations = validationSchemas(t);

  const steps = [
    { key: 'customer_data', label: t('customer') },
    { key: 'addresses', label: t('billing and shipping addresses') },
    { key: 'payment_method', label: t('payment') },
    { key: 'shipping_method', label: t('shipping_method') },
    { key: 'order_review', label: t('order review') },
  ];

  const handleAccordionChange = (step) => {
    const hasBillingEmail = order && order.billing.email;
    const isBillingAddressValid = order && hasBillingEmail && validateBillingAddress(order.billing);
    const isShippingAddessValid = order && validateShippingAddress(order.shipping);
    const hasPaymentMethod = order && order.payment_method;
    const hasShippingLine = order && order.shipping_lines?.length >= 1;

    if (step === 0) {
      setCurrentStep(step);
    }
    if (step === 1) {
      hasBillingEmail && setCurrentStep(step);
    }
    if (step === 2) {
      isBillingAddressValid && isShippingAddessValid && setCurrentStep(step);
    }
    if (step === 3) {
      hasPaymentMethod && setCurrentStep(step);
    }
    if (step === 4) {
      hasShippingLine && setCurrentStep(step);
    }
  };

  useLayoutEffect(() => {
    if (currentOrder) {
      if (currentStep === 0) {
        currentOrder.billing.email && setCurrentStep((prev) => prev + 1);
      }
      if (currentStep === 1) {
        validateBillingAddress(currentOrder.billing) &&
          validateShippingAddress(currentOrder.shipping) &&
          setCurrentStep((prev) => prev + 1);
      }
      if (currentStep === 2) {
        currentOrder.payment_method && setCurrentStep((prev) => prev + 1);
      }
      if (currentStep === 3) {
        currentOrder.shipping_lines.length >= 1 && setCurrentStep((prev) => prev + 1);
      }
      localStorage.setItem('currentOrder', JSON.stringify(currentOrder));
    }
  }, [currentOrder]);

  /*   useLayoutEffect(() => {
    
    if (currentOrder && currentOrder.payment_method === 'bacs') {
      history.push(`/${language}/shop/checkout/success`);
    }
  }, [currentOrder]); */

  const createInitialOrder = (customerData, next) => {
    const newOrder = {
      billing: {
        email: customerData.email,
      },
      line_items: cart.items.map((item) => ({
        product_id: item.id,
        quantity: item.quantity.value,
        meta_data: convertToArray(item.cart_item_data),
      })),
    };

    if (customerData.id) {
      newOrder.customer_id = customerData.id;
    }

    setOrder(newOrder);
    console.log(newOrder);
    dispatch(start(newOrder, finishSuccess, finishError, postOrderPath(), 'POST'));
  };

  const handleSubmit = (values) => {
    //const newOrder = clone(currentOrder);
    const newOrder = {
      id: currentOrder.id,
    };

    if (steps[currentStep].key === 'addresses') {
      newOrder.billing = {
        ...currentOrder.billing,
        ...values.billing,
      };
      if (values.same_address) {
        newOrder.shipping = {
          ...values.billing,
        };

        delete newOrder.shipping.phone;
      } else {
        newOrder.shipping = {
          ...values.shipping,
        };
      }
    }

    if (steps[currentStep].key === 'payment_method') {
      newOrder.payment_method = values.payment_method;
    }

    if (steps[currentStep].key === 'shipping_method') {
      const shippingMethodData = shippingMethods.find((method) => method.method_id === values.shipping_method);

      const shippingLine = {
        method_id: values.shipping_method,
        method_title: shippingMethodData.method_title,
      };

      if (values.shipping_method === 'flat_rate') {
        shippingLine.total = shippingMethodData.settings.cost.value;
      }

      newOrder.shipping_lines = [shippingLine];
    }
    setOrder(newOrder);
    console.log(currentStep, newOrder);
    dispatch(start(newOrder, finishSuccess, finishError, postOrderPath(newOrder.id), 'PUT'));
  };

  const handlePaymentStart = () => {
    const hasBillingEmail = currentOrder && currentOrder.billing.email;
    const isBillingAddressValid = currentOrder && hasBillingEmail && validateBillingAddress(currentOrder.billing);
    const isShippingAddessValid = currentOrder && validateShippingAddress(currentOrder.shipping);
    const hasPaymentMethod = currentOrder && currentOrder.payment_method;
    const hasShippingLine = currentOrder && currentOrder.shipping_lines?.length >= 1;

    const newOrder = clone(currentOrder);

    if (isBillingAddressValid && isShippingAddessValid && hasPaymentMethod && hasShippingLine) {
      if (newOrder.payment_method === 'bacs') {
        newOrder.status = 'on-hold';
      }
      dispatch(start(newOrder, finishSuccess, finishError, postOrderPath(order.id), 'PUT'));
    } else {
      setOrderError('Hiba történt! Valamit hiányosan töltöttél ki, kérjük ellenőrizd az adatokat!');
    }
  };

  const handlePaypalTransactionId = (transactionId) => {
    const newOrder = {};

    newOrder.transaction_id = transactionId;

    dispatch(start(newOrder, finishSuccess, finishError, postOrderPath(currentOrder.id), 'PUT'));
  };

  return (
    <PayPalScriptProvider options={{ 'client-id': paypalClientId, currency: 'HUF', locale: 'hu_HU' }}>
      <PageContainer>
        <h1 className="text-3xl pb-4">{t('checkout')}</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 ">
          <div className="col-span-2 lg:pr-12 pb-4 lg:pb-0">
            <AccordionItem
              step={0}
              label={steps[0].label}
              active={currentStep === 0}
              onAccordionClick={handleAccordionChange}
              displayIcon={currentOrder && !!currentOrder.billing.email}
            >
              <CustomerInfo
                onNext={createInitialOrder}
                next={1}
                loading={loading}
                user={user}
                guestEmail={currentOrder?.billing?.email}
              />
            </AccordionItem>
            <Formik
              initialValues={generateInitialValues(cart, user)}
              onSubmit={handleSubmit}
              validationSchema={translatedValidations[currentStep]}
              enableReinitialize={true}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <AccordionItem
                    step={1}
                    label={steps[1].label}
                    active={currentStep === 1}
                    onAccordionClick={handleAccordionChange}
                    displayIcon={
                      currentOrder &&
                      validateBillingAddress(currentOrder.billing) &&
                      validateShippingAddress(currentOrder.shipping)
                    }
                  >
                    <AddressForm formFields={formFields} values={values} loading={loading} />
                  </AccordionItem>
                  <AccordionItem
                    step={2}
                    label={steps[2].label}
                    active={currentStep === 2}
                    onAccordionClick={handleAccordionChange}
                    displayIcon={currentOrder && currentOrder.payment_method}
                  >
                    <PaymentMethod formFields={formFields} values={values} loading={loading} />
                  </AccordionItem>
                  <AccordionItem
                    step={3}
                    label={steps[3].label}
                    active={currentStep === 3}
                    onAccordionClick={handleAccordionChange}
                    displayIcon={currentOrder && currentOrder.shipping_lines?.length >= 1}
                  >
                    <ShippingMethod
                      loading={loading}
                      values={values}
                      freeShipping={cart && cart.totals.subtotal > 25000}
                    />
                  </AccordionItem>
                  <AccordionItem
                    step={4}
                    label={steps[4].label}
                    active={currentStep === 4}
                    onAccordionClick={handleAccordionChange}
                  >
                    <>
                      {orderError && <div className="border border-red-500 p-4 text-red-500 mb-4">{orderError}</div>}
                      <OrderReview
                        values={values}
                        cart={cart}
                        order={currentOrder}
                        onPlaceOrder={handlePaymentStart}
                        loading={loading}
                        shippingMethods={shippingMethods}
                        onAddTransactionId={handlePaypalTransactionId}
                      />
                    </>
                  </AccordionItem>
                </Form>
              )}
            </Formik>
          </div>
          <div className="">
            <div className="bg-gray-100 p-6">
              <OrderSummary cart={cart} checkout={true} onPlaceOldar={handlePaymentStart} />
            </div>
          </div>
        </div>
      </PageContainer>
    </PayPalScriptProvider>
  );
};

Checkout.propTypes = {};

export default Checkout;
