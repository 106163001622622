import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RoundLoader from '../../../../shared/components/round-loader/RoundLoader';
import TextField from './../../../../shared/components/text-field/TextField';

const guestUserValidationSchema = (t) =>
  Yup.object({
    email: Yup.string().email(t('e-mail is not correct')).required(t('e-mail is required')),
    emailConfirm: Yup.string()
      .oneOf([Yup.ref('email'), null], t('e-mails must match'))
      .required(t('confiramtion is required')),
  });

const GuestUser = ({ handleSubmit, loading, guestEmail }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: guestEmail ? guestEmail : '',
        emailConfirm: guestEmail ? guestEmail : '',
      }}
      onSubmit={handleSubmit}
      validationSchema={guestUserValidationSchema(t)}
      enableReinitialize={true}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div>
            <Field name="email" component={TextField} placeholder={t('email')} />
          </div>
          <div className="pb-4">
            <Field name="emailConfirm" component={TextField} placeholder={t('confirm you email')} />
          </div>
          <button
            type="submit"
            className="border border-green py-4 px-6 flex items-center justify-center w-full lg:w-60 text-green hover:bg-green hover:text-white"
            disabled={loading}
          >
            {loading ? <RoundLoader /> : t('checkout as a guest')}
          </button>
        </Form>
      )}
    </Formik>
  );
};

GuestUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  guestEmail: PropTypes.string,
};

export default GuestUser;
