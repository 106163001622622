import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Loader from '../../../shared/components/loader/Loader';

import { generateProductUrl } from './../../../shared/frontend-routes';

import { numberFormatter } from './../../../shared/helpers';

const ProductListItemContainer = styled.div`
  .imageContainer {
    height: 60vh;
  }
  /* 	@media (min-width: 640px) {

   } */
  @media (min-width: 768px) {
    .imageContainer {
      height: 56vh;
    }
  }
  @media (min-width: 1024px) {
    .imageContainer {
      height: 28vh;
    }
  }

  @media (min-width: 1280px) {
    .imageContainer {
      height: 50vh;
    }
  }

  @media (min-width: 1536px) {
    .imageContainer {
      height: 60vh;
    }
  }

  .imageLoader {
    height: 60vh;
  }
`;

function ProductListItem({ product }) {
  const [showBack, setShowBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const mainImage = product.images[0];
  const backImage = product.images.find((img) => img.alt === 'back');

  const isNewCollection = product.tags.some((tag) => tag.slug === 'new-collection');

  const handleHover = () => setShowBack((prev) => !prev);

  const handleLoading = () => setLoading(false);

  return (
    <ProductListItemContainer>
      <Link to={generateProductUrl('hu', `${product.id}-${product.slug}`)}>
        <div className="imageContainer overflow-hidden relative" onMouseEnter={handleHover} onMouseLeave={handleHover}>
          {loading && (
            <div className="w-full h-full flex items-center justify-center bg-green bg-opacity-10">
              <Loader />
            </div>
          )}
          {!loading && isNewCollection && (
            <div className="absolute top-0 left-0 p-1 text-xs text-white bg-green">New collection</div>
          )}
          {backImage && (
            <img
              className={`object-scale-down w-full backImage ${showBack ? 'block' : 'hidden'}`}
              src={backImage.src}
            />
          )}
          <img
            className={`object-scale-down w-full ${!showBack ? 'block' : 'hidden'}`}
            src={mainImage.src}
            onLoad={handleLoading}
          />
        </div>
        <div className="flex items-top justify-between px-4 pb-8 pt-1">
          <div className="uppercase text-sm text-left font-bold">{product.name}</div>
          <div className="text-right text-sm whitespace-nowrap font-light">
            {product.regular_price ? numberFormatter(product.regular_price) : numberFormatter(product.price)} HUF
          </div>
        </div>
      </Link>
    </ProductListItemContainer>
  );
}

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductListItem;
