import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { pageSelector } from './../../store/slices/pageSlice';
import PageContainer from './../../shared/components/page-container/PageContainer';

import { createMarkup } from '../../shared/helpers';

import { ReactComponent as Icon1 } from './../../shared/images/sustainability/Elischer icons-01.svg';
import { ReactComponent as Icon2 } from './../../shared/images/sustainability/Elischer icons-02.svg';
import { ReactComponent as Icon3 } from './../../shared/images/sustainability/Elischer icons-03.svg';
import { ReactComponent as Icon4 } from './../../shared/images/sustainability/Elischer icons-04.svg';
import { ReactComponent as Icon5 } from './../../shared/images/sustainability/Elischer icons-05.svg';
import { ReactComponent as Icon6 } from './../../shared/images/sustainability/Elischer icons-06.svg';
import { ReactComponent as Icon7 } from './../../shared/images/sustainability/Elischer icons-08.svg';
import { ReactComponent as Icon8 } from './../../shared/images/sustainability/Elischer icons-09.svg';
import { ReactComponent as Icon9 } from './../../shared/images/sustainability/Elischer icons-10.svg';
import { ReactComponent as Icon10 } from './../../shared/images/sustainability/Elischer icons-11.svg';
import { ReactComponent as Icon11 } from './../../shared/images/sustainability/Elischer icons-12.svg';
import { ReactComponent as Icon12 } from './../../shared/images/sustainability/Elischer icons-13.svg';
import { ReactComponent as Icon13 } from './../../shared/images/sustainability/Elischer icons-14.svg';
import { ReactComponent as Icon14 } from './../../shared/images/sustainability/Elischer icons-15.svg';

const pics = [Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9, Icon10, Icon11, Icon12, Icon13, Icon14];

const SustainabilityPage = (props) => {
  const { pages } = useSelector(pageSelector);

  const page = useMemo(() => {
    return pages.find((page) => page.slug === 'fenntarthatosag');
  }, [pages]);

  const [first, second] = page.content.rendered.split('<hr />');

  return (
    <PageContainer>
      <div className="mx-auto lg:w-8/12 px-8 lg:px-0">
        <h1 className="text-2xl text-center lg:text-left">{page.title.rendered}</h1>
        <div className="flex flex-col py-12">
          <div dangerouslySetInnerHTML={createMarkup(first)}></div>
          <div className="grid grid-cols-3 md:grid-cols-7 py-8">
            {pics.map((Icon, index) => (
              <div key={index} className="p-2">
                <Icon className="w-full" />
              </div>
            ))}
          </div>
          <div dangerouslySetInnerHTML={createMarkup(second)}></div>
        </div>
      </div>
    </PageContainer>
  );
};

SustainabilityPage.propTypes = {};

export default SustainabilityPage;
