import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";

const CustomCookieConsent = props => {

    const handleAccept = () => {
      //window.bp('consent', 'grantConsent');
    }

    return (
      <div>
        <CookieConsent 
        //debug={true}
        location="bottom"
        buttonText="Elfogadás"
        //cookieName="myAwesomeCookieName2"

        onAccept={handleAccept}
        style={{ background: "#7fa88b", fontSize: "12px"}}
        buttonStyle={{ color: "#7fa88b", background: "white", fontSize: "12px" }}
        >
        Sütiket használunk a hozzád eljuttatott tartalom testreszabása és fejlesztése érdekében, garantálva, hogy mindig a lehető legjobb online vásárlási élményben legyen részed. Az Elfogadás gombra kattintva elfogadod, hogy ilyen technológiákat használunk marketing és elemzés céljára. Lásd az Adatvédelmi szabályzatot</CookieConsent>
      </div>
    );
};

CustomCookieConsent.propTypes = {
    
};

export default CustomCookieConsent;