import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

import RoundLoader from './../../../shared/components/round-loader/RoundLoader';
import PaymentMethodField from './../../../shared/components/payment-method-field/PaymentMethodField';

const PaymentMethod = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col w-full pt-8">
        <Field
          type="radio"
          value="bacs"
          component={PaymentMethodField}
          name="payment_method"
          header={t('SEPA Transfer')}
          text={t('Transfer in advance')}
          icon={() => <></>}
        />
        <Field
          type="radio"
          value="ppcp-gateway"
          component={PaymentMethodField}
          name="payment_method"
          header={t('Credit or Debit Card')}
          text={t('with paypal')}
          icon={() => (
            <table border="0" cellPadding="10" cellSpacing="0" align="center">
              <tbody>
                <tr>
                  <td align="center">
                    <a
                      href="https://www.paypal.com/webapps/mpp/paypal-popup"
                      title="How PayPal Works"
                      onClick={() => {
                        window.open(
                          'https://www.paypal.com/webapps/mpp/paypal-popup',
                          'WIPaypal',
                          'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'
                        );
                        return false;
                      }}
                    >
                      <img
                        src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
                        border="0"
                        alt="PayPal Logo"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        />
        <div className="text-red-500 mb-4">
          <ErrorMessage name="payment_method" />
        </div>
      </div>
      <button
        type="submit"
        className="border border-green py-4 px-6 flex items-center justify-center w-full lg:w-60 text-green hover:bg-green hover:text-white"
        disabled={loading}
      >
        {loading ? <RoundLoader /> : t('next')}
      </button>
    </>
  );
};

PaymentMethod.propTypes = {
  loading: PropTypes.bool,
};

export default PaymentMethod;
