import { call, put, all, select } from 'redux-saga/effects';
import { httpRequest } from './api-calls';
import toast from 'react-hot-toast';

import { userSelector } from './../slices/userSlice';

export function* cartWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type, toastMessage },
  } = action;

  const { auth } = yield select(userSelector);

  const apiCall = httpRequest(url, type);

  try {
    const { data } = yield call(apiCall, payload);

    const oldCartKey = localStorage.getItem('cart_key');

    if (!auth && !oldCartKey) {
      localStorage.setItem('cart_key', data.cart_key);
    }

    toastMessage && toast(toastMessage);
    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
