import * as Yup from 'yup';

export const formFields = {
  billing: [
    {
      name: 'last_name',
      label: 'last name',
      requiredMessage: '',
    },
    {
      name: 'first_name',
      label: 'first name',
      requiredMessage: '',
    },
    {
      name: 'address_1',
      label: 'Address line 1',
      requiredMessage: '',
    },
    {
      name: 'address_2',
      label: 'Address line 2',
    },
    /*  {
      name: 'company',
      label: 'Company',
      requiredMessage: '',
    }, */
    {
      name: 'city',
      label: 'City',
      requiredMessage: '',
    },
    {
      name: 'state',
      label: 'State',
      requiredMessage: '',
    },
    {
      name: 'postcode',
      label: 'Postcode',
      requiredMessage: '',
    },
    {
      name: 'country',
      label: 'Country',
      requiredMessage: '',
    },
    {
      name: 'phone',
      label: 'Phone',
      requiredMessage: '',
    },
  ],
  same_address: {
    name: 'same_address',
    label: 'Use this address as my shipping address',
  },
  shipping: [
    {
      name: 'last_name',
      label: 'last name',
      requiredMessage: '',
    },
    {
      name: 'first_name',
      label: 'first name',
      requiredMessage: '',
    },
    {
      name: 'address_1',
      label: 'Address line 1',
      requiredMessage: '',
    },
    {
      name: 'address_2',
      label: 'Address line 2',
    },
    /* {
      name: 'company',
      label: 'Company',
      requiredMessage: '',
    }, */
    {
      name: 'city',
      label: 'City',
      requiredMessage: '',
    },
    {
      name: 'state',
      label: 'State',
      requiredMessage: '',
    },
    {
      name: 'postcode',
      label: 'Postcode',
      requiredMessage: '',
    },
    {
      name: 'country',
      label: 'Country',
      requiredMessage: '',
    },
  ],
  payment_method: {
    name: 'payment_method',
    label: 'Payment method',
    requiredMessage: 'Payment method is required.',
  },
};

const checkShippingMethod = (cart, order) => {
  if (order && Array.isArray(order.shipping_lines)) {
    return order.shipping_lines[0].method_id;
  }

  return cart && parseInt(cart.totals.subtotal, 10) > 25000 ? 'free_shipping' : 'flat_rate';
};

export const generateInitialValues = (cart, user, order) => {
  return {
    billing: {
      first_name: user ? user.billing.first_name : 'Erik',
      last_name: user ? user.billing.last_name : 'Banhalmi',
      address_1: user && user.billing ? user.billing.address_1 : 'naphegy ter 4',
      address_2: user && user.billing ? user.billing.address_2 : '',
      city: user && user.billing ? user.billing.city : 'Budapest',
      state: user && user.billing ? user.billing.state : 'Budapest',
      postcode: user && user.billing ? user.billing.postcode : '1016',
      country: user && user.billing ? user.billing.country : 'Magyarorszag',
      phone: user && user.billing ? user.billing.phone : '+36302522490',
    },
    same_address: true,
    shipping: {
      first_name: user ? user.shipping.first_name : '',
      last_name: user ? user.shipping.last_name : '',
      address_1: user && user.shipping ? user.shipping.address_1 : '',
      address_2: user && user.shipping ? user.shipping.address_2 : '',
      city: user && user.shipping ? user.shipping.city : '',
      state: user && user.shipping ? user.shipping.state : '',
      postcode: user && user.shipping ? user.shipping.postcode : '',
      country: user && user.shipping ? user.shipping.phone : '',
    },
    payment_method: '',
    shipping_method: checkShippingMethod(cart, order),
  };
};

export const validationSchemas = (t) => [
  {},
  Yup.object({
    billing: Yup.object({
      first_name: Yup.string().required(t('Please specify your first name.')),
      last_name: Yup.string().required(t('Please specify your last name.')),
      address_1: Yup.string().required(t('Please specify an address.')),
      city: Yup.string().required(t('Please specify a city.')),
      state: Yup.string().required(t('Please specify a state.')),
      postcode: Yup.string().required(t('Please specify a postcode.')),
      country: Yup.string().required(t('Please specify a country.')),
      phone: Yup.string().required(t('Please specify a phone number.')),
    }),
  }),
  Yup.object({
    payment_method: Yup.string().required(t('Please specify a payment method.')),
  }),
  Yup.object({
    shipping_method: Yup.string().required(t('Please specify a shipping method.')),
  }),
  {},
];

export const validateBillingAddress = (address) => {
  return (
    address.first_name &&
    address.last_name &&
    address.address_1 &&
    address.city &&
    address.state &&
    address.postcode &&
    address.country &&
    address.phone
  );
};

export const validateShippingAddress = (address) => {
  return (
    address &&
    address.first_name &&
    address.last_name &&
    address.address_1 &&
    address.city &&
    address.state &&
    address.postcode &&
    address.country
  );
};
