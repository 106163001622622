import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  hu: {
    translation: {
      'About us': 'Rólunk',
      checkout: 'Pénztár',
      customer: 'Vásárló',
      login: 'Bejelentkezés',
      'sign up': 'Regisztráció',
      'checkout as a guest': 'Vásárlás vendégként',
      'billing and shipping addresses': 'Számlázási és szállítási cím',
      payment: 'Fizetési mód',
      shipping_method: 'Szállítási mód',
      'order review': 'Rendelés áttekintése',
      total: 'Összesen',
      shipping: 'Szállítás',
      'same address': 'Számlázási és szállítási cím megegyezik',
      next: 'Tovább',
      'wire transfer': 'átutalás',
      card: 'kártyával',
      'place order': 'megrendelés',
      'order summary': 'Rendelés összegzése',
      subtotal: 'Részösszeg',
      'proceed to checkout': 'Tovább a pénztárhoz',
      'continue shopping': 'Vásárlás folytatása',
      'Your shopping cart': 'Bevásárlókosarad',
      product: 'Termék',
      measurements: 'Méretek',
      color: 'Szín',
      quantity: 'Mennyiség',
      price: 'Ár',
      'total items': 'Összes termék',
      'total value without shipping': 'Teljes összeg szállítás nélkül',
      'checkout as a guest': 'Vásárlás vendégként',
      email: 'E-mail',
      'confirm you email': 'E-mail még egyszer',
      'e-mail is not correct': 'E-mail nem megfelelő',
      'e-mail is required': 'E-mail kitöltése kötelező',
      'e-mails must match': 'Megadott e-mail címek nem egyeznek',
      'confiramtion is required': 'Megerősítése kötelező',
      'full name': 'Teljes név',
      'Address line 1': 'Utca, házszám, emelet',
      'Address line 2': 'Egyéb adatok',
      City: 'Település',
      State: 'Megye',
      Postcode: 'Irányítószám',
      Country: 'Ország',
      Phone: 'Telefonszám',
      'billing address': 'Számlázási cím',
      'shipping address': 'Szállítási cím',
      'Shipping address is the same': 'Szállítási és számlázási cím megegyezik',
      'SEPA Transfer': 'Banki átutalással',
      'Credit or Debit Card': 'Bankkártyás fizetés',
      'with barion': 'Barionnal',
      'with paypal': 'Paypal szolgáltatóval',
      'Transfer in advance': 'Fizetés előreutalással, közvetlenül a bankszámlánkra',
      'Flat rate': 'Fix ár',
      'Free shipping': 'Ingyenes szállítás',
      flat_rate_description:
        'Szállítás 1500 forint: Budapest és vonzáskörzetének területén személyesen juttatjuk el rendelésed, az ország más területére a Foxpost futárszolgálata biztosítja a házhozszállítást.',
      free_shipping_description:
        'Ingyenes: 25.000 forint értékű vásárlás felett a szállítás ingyenes. Budapest és vonzáskörzetének területén személyesen juttatjuk el rendelésed, az ország más területére a Foxpost futárszolgálata biztosítja a házhozszállítást.',
      addresses: 'Címek',
      items: 'Termékek',
      'finalizie order': 'Rendelés véglegesítése',
      'add to cart': 'Kosárba',
      'last name': 'Vezetéknév',
      'first name': 'Keresztnév',
      password: 'Jelszó',
      'confirm your password': 'Jelszó még egyszer',
      register: 'Regisztrálok',
      sizes: 'Méretek',
      colors: 'Színek',
      Magassag: 'Magasság',
      Vallszelesseg: 'Vállszélesség',
      Csipoboseg: 'Csípőbőség',
      Derekboseg: 'Derékbőség',
      Mellboseg: 'Mellbőség',
      Mennyiseg: 'Mennyiség',
      name: 'Név',
      message: 'Üzenet',
      'contact us': 'Kapcsolat',
      send: 'Küldés',
      'name is required': 'A név kitöltése kötelező',
      'text is required': 'Írj üzenetet',
      orders: 'Rendeléseid',
      'tul nagy': 'Túl nagy',
      'csak szamokat adjon meg': 'Csak számokat adjon meg',
      'kotelezo megadni': 'Kötelező megadni',
      'Kerlek valasz szint': 'Kérlek válasz színt',
      your_profile: 'A profilod',
      personal: 'Személyes',
      submit: 'Elküld',
      email_sent: 'Az e-mailt sikeresen elküldted',
      email_error: 'Hiba történt az email küldése során.',
      'Please specify your first name.': 'Kérjük add meg a keresztneved.',
      'Please specify your last name.': 'Kérjük add meg a vezetékneved.',
      'Please specify an address.': 'Kérjük add meg a címed.',
      'Please specify a city.': 'Kérjük add meg a városod.',
      'Please specify a state.': 'Kérjük add meg a megyédet.',
      'Please specify a postcode.': 'Kérjük add meg az irányítószámod.',
      'Please specify a country.': 'Kérjük add meg az országod.',
      'Please specify a phone number.': 'Kérjük add meg a telefonszámod.',
      'order number': 'Rendelési szám',
      date: 'Dátum',
      status: 'Rendelés státusza',
      sum: 'Összeg',
      action: 'Műveletek',
      pending: 'Fizetés folyamatban',
      processing: 'Feldolgozás alatt',
      'on-hold': 'Fizetésre vár',
      completed: 'Teljesítve',
      cancelled: 'Visszamondva',
      refunded: 'Visszatérítve',
      failed: 'Sikertelen',
    },
  },
  en: {
    translation: {
      'About us': 'About us',
      checkout: 'checkout',
      customer: 'customer',
      login: 'Login',
      'sign up': 'Sign up',
      'checkout as a guest': 'checkout as a guest',
      'billing and shipping addresses': 'billing and shipping addresses',
      payment: 'payment method',
      shipping_method: 'Shipping method',
      'order review': 'order review',
      total: 'total',
      shipping: 'shipping',
      'same address': 'Shipping address is the same',
      next: 'Next',
      'wire transfer': 'wire transfer',
      card: 'by card',
      'place order': 'place order',
      'order summary': 'Order summary',
      subtotal: 'Subtotal',
      'proceed to checkout': 'Proceed to checkout',
      'continue shopping': 'Continue shopping',
      'Your shopping cart': 'Your shopping cart',
      product: 'Product',
      measurements: 'Measurements',
      color: 'Color',
      quantity: 'Quantity',
      price: 'Price',
      'total items': 'Total items',
      'total value without shipping': 'Total value without shipping',
      'checkout as a guest': 'Checkout as a guest',
      email: 'Email',
      'confirm you email': 'Confirm you email',
      'e-mail is not correct': 'E-mail is not correct',
      'e-mail is required': 'E-mail is required',
      'e-mails must match': 'E-mails must match',
      'confiramtion is required': 'Confiramtion is required',
      'full name': 'Full name',
      'Address line 1': 'Address line 1',
      'Address line 2': 'Address line 2',
      City: 'City',
      State: 'State',
      Postcode: 'Postcode',
      Country: 'Country',
      Phone: 'Phone',
      'billing address': 'Billing address',
      'shipping address': 'Shipping address',
      'Shipping address is the same': 'Shipping address is the same',
      'SEPA Transfer': 'SEPA Transfer',
      'Credit or Debit Card': 'Credit or Debit Card',
      'with barion': 'with Barion',
      'Transfer in advance': 'Transfer in advance',
      'Flat rate': 'Flat rate',
      'Free shipping': 'Free shipping',
      flat_rate_description: 'Our flate rate is 1500 HUF in Hungary',
      free_shipping_description:
        'You are eligible for free shipping if you order total is more than 25000 HUF or you received a coupon',
      addresses: 'Addresses',
      items: 'Items',
      'finalizie order': 'Finalize order',
      'add to cart': 'Add to cart',
      'last name': 'Last name',
      'first name': 'First name',
      password: 'Password',
      'confirm your passowrd': 'Confirm your password',
      register: 'Register',
      sizes: 'Sizes',
      colors: 'Colors',
      Magassag: 'Magassag',
      Vallszelesseg: 'Vállszélesség',
      Csipoboseg: 'Csipoboseg',
      Derekboseg: 'Derekboseg',
      Mellboseg: 'Mellbőség',
      name: 'Name',
      message: 'Message',
      'contact us': 'Contact us',
      send: 'Send',
      'name is required': 'Name is required',
      'text is required': 'Text is required',
      orders: 'Orders',
      'tul nagy': 'Please specify a smaller number',
      'csak szamokat adjon meg': 'Only numbers',
      'kotelezo megadni': 'This field is required',
      'Kerlek valasz szint': 'Pick a color',
      your_profile: 'Your profile',
      personal: 'Personal',
      submit: 'Submit',
      email_sent: 'Email sent successfully.',
      email_error: 'There was an error while sending your email.',
      'Please specify your first name.': 'Please specify your first name.',
      'Please specify your last name.': 'Please specify your last name.',
      'Please specify an address.': 'Please specify an address.',
      'Please specify a city.': 'Please specify a city.',
      'Please specify a state.': 'Please specify a state.',
      'Please specify a postcode.': 'Please specify a postcode.',
      'Please specify a country.': 'Please specify a country.',
      'Please specify a phone number.': 'Please specify a phone number.',
      'order number': 'Order number',
      date: 'Date',
      status: 'Order status',
      sum: 'Sum',
      action: 'Actions',
      pending: 'Pending payment',
      processing: 'Processing',
      'on-hold': 'On-Hold',
      completed: 'Completed',
      cancelled: 'Cancelled',
      refunded: 'Refunded',
      failed: 'Failed',
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: 'hu',
    fallbackLng: 'hu',
    keySeparator: false,
    lowerCaseLng: true,
    supportedLngs: ['hu', 'en'],
    interpolation: {
      escapeValue: false,
    },
    //debug: true,
    detection: {
      lookupFromPathIndex: 0,
      order: ['localStorage', 'path'],
    },
  });

export default i18n;
