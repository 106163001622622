const generateCategoryUrl = (lang, category) => `/${lang}/shop/${category ? category : ''}`;

const generateProductUrl = (lang, productSlug) => `/${lang}/shop/product/${productSlug}`;

const generateCartUrl = (lang) => `/${lang}/shop/cart`;

const generateCheckoutUrl = (lang) => `/${lang}/shop/checkout`;

const orderDetailsUrl = (lang, orderId) => `/${lang}/orders/${orderId}`;

export { generateCategoryUrl, generateProductUrl, generateCartUrl, generateCheckoutUrl, orderDetailsUrl };
