import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink, Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppContext } from './../../../components/app/AppContext';

import { ReactComponent as Logo } from './../../images/typo-logo2.svg';
import CartIcon from './../cart-icon/CartIcon';
import HeartIcon from './../heart-icon/HeartIcon';
import UserIcon from './../user-icon/UserIcon';
import MenuIcon from './../menu-icon/MenuIcon';
import XIcon from './../x-icon/XIcon';

import HeaderCart from './../../../components/webshop/header-cart/HeaderCart';
import HeaderLogin from './../header-login/HeaderLogin';

import { cartItemsNumberSelector } from './../../../store/slices/cartSlice';
import { userSelector } from './../../../store/slices/userSlice';

import { generateCategoryUrl, generateCartUrl } from './../../frontend-routes';

const generateSubmenus = (webshopMenus, lang) => {
  const subMenus = {};

  webshopMenus.forEach((item) => {
    subMenus[item.slug] = [
      <NavLink
        key={item.name}
        to={generateCategoryUrl(lang, item.slug)}
        aria-current={item.current ? 'page' : undefined}
        className="text-base py-1 tracking-normal"
      >
        All
      </NavLink>,
    ];

    subMenus[item.slug] = subMenus[item.slug].concat(
      item.children.map((child) => (
        <NavLink
          key={child.name}
          to={generateCategoryUrl(lang, `${item.slug}/${child.slug}`)}
          aria-current={item.current ? 'page' : undefined}
          className="text-base py-1 tracking-wide"
        >
          {child.name}
        </NavLink>
      ))
    );
  });

  return subMenus;
};

const HeaderContainer = styled.nav.attrs({
  className: 'w-screen fixed flex-col bg-white z-10',
})``;

const Header = ({ webshopMenu, onLogout }) => {
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const { itemsNumber } = useSelector(cartItemsNumberSelector);
  const { auth } = useSelector(userSelector);

  const { language } = useContext(AppContext);

  const subMenus = generateSubmenus(webshopMenu, language);

  const handleHover = (submenu) => {
    setActiveSubmenu(submenu);
  };

  const handleLogout = () => {
    onLogout();
  };

  const handleOpen = () => setOpen((prev) => !prev);

  const handleMobileSubmenuOpen = (submenu) => {
    if (activeSubmenu === submenu) {
      setActiveSubmenu('');
    } else {
      setActiveSubmenu(submenu);
    }
  };

  const handleLoginOpen = () => setLoginOpen((prev) => !prev);

  return (
    <HeaderContainer onMouseLeave={() => handleHover()}>
      <div className="w-screen flex lg:py-6 justify-between">
        <button
          className="inline-flex lg:hidden items-center justify-center p-2 text-black hover:text-white hover:bg-gray-700"
          onClick={handleOpen}
        >
          <span className="sr-only">Open main menu</span>
          {open ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
        </button>

        <div className="flex-1 hidden lg:flex justify-start mr-auto pl-8">
          {webshopMenu.map((item) => (
            <NavLink
              key={item.name}
              to={generateCategoryUrl(language, item.slug)}
              aria-current={item.current ? 'page' : undefined}
              className="uppercase text-base px-4 tracking-widest"
              onMouseEnter={() => handleHover(item.slug)}
            >
              {item.name}
            </NavLink>
          ))}
          <div className="group relative dropdown cursor-pointer">
            <div className="uppercase text-base px-4 tracking-widest ">About us</div>
            <div className="group-hover:block dropdown-menu absolute hidden h-auto">
              <ul className="bg-white pb-2 pt-6 top-0">
                <li className="py-1">
                  <NavLink to={`/${language}/fenntarthatosag`} className="uppercase text-base px-4 tracking-widest">
                    Fenntarthatóság
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to={`/${language}/our-story`} className="uppercase text-base px-4 tracking-widest">
                    Történetünk
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to={`/${language}/contact`} className="uppercase text-base px-4 tracking-widest">
                    Kapcsolat
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- Logo --> */}
        <div className="w-44 lg:mx-12 p-4 lg:p-0">
          <NavLink to="/hu/">
            <Logo />
          </NavLink>
        </div>
        {/*  <!-- Right Navigation --> */}
        <div className="flex-1 lg:flex justify-end ml-auto pr-8 hidden">
          <div className="group relative dropdown cursor-pointer">
            <button className=" mx-2 flex uppercase text-base items-center">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-6 w-6 mr-1" />
              You
            </button>
            <div className="group-hover:block dropdown-menu hidden absolute right-0 h-auto">
              {auth ? (
                <ul className="bg-white pb-2 pt-6 top-0">
                  <li className="py-1">
                    <NavLink to={`/${language}/profile`} className="uppercase text-base px-4 tracking-widest">
                      Profilom
                    </NavLink>
                  </li>
                  <li className="py-1">
                    <NavLink to={`/${language}/orders`} className="uppercase text-base px-4 tracking-widest">
                      Rendeléseim
                    </NavLink>
                  </li>
                  <li className="py-1">
                    <button type="button" onClick={handleLogout} className="uppercase text-base px-4 tracking-widest">
                      Kijelentkezés
                    </button>
                  </li>
                </ul>
              ) : (
                <HeaderLogin />
              )}
            </div>
          </div>

          {/*  <button type="button" className="mx-2 flex uppercase text-base items-center">
            <span className="sr-only">View whishlist</span>
            <HeartIcon className="h-6 w-6 mr-1" />
            Whislist
          </button> */}
          <NavLink to={generateCartUrl(language)} className="mx-2 flex uppercase text-base items-center">
            <span className="sr-only">View cart</span>
            <CartIcon className="h-6 w-6 mr-1" /> Bag ({itemsNumber})
          </NavLink>
        </div>
      </div>
      {open && (
        <div className="h-screen bg-white flex flex-col items-start">
          {webshopMenu.map((item) => (
            <React.Fragment key={item.name}>
              <button
                type="button"
                className="uppercase text-2xl my-1 px-4 tracking-widest"
                onClick={() => handleMobileSubmenuOpen(item.slug)}
              >
                {item.name}
              </button>

              {activeSubmenu === item.slug ? (
                <div className="pl-12 flex flex-col items-start pb-4">{subMenus[activeSubmenu]}</div>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
          <NavLink to={`/${language}/fenntarthatosag`} className="uppercase text-2xl my-1 px-4 tracking-widest">
            Fenntarthatóság
          </NavLink>

          <NavLink to={`/${language}/our-story`} className="uppercase text-2xl my-1 px-4 tracking-widest">
            Történetünk
          </NavLink>

          <NavLink to={`/${language}/contact`} className="uppercase text-2xl my-1 px-4 tracking-widest">
            Kapcsolat
          </NavLink>
          <NavLink
            to={generateCartUrl(language)}
            className="uppercase text-2xl my-1 px-4 tracking-widest flex items-center justify-center"
          >
            <span className="sr-only">View cart</span>
            <CartIcon className="h-6 w-6 mr-1" /> Bag ({itemsNumber})
          </NavLink>
          <button
            className="uppercase text-2xl my-1 px-4 tracking-widest flex items-center justify-center"
            onClick={handleLoginOpen}
          >
            <span className="sr-only">Open user menu</span>
            <UserIcon className="h-6 w-6 mr-1" />
            You
          </button>
          {loginOpen && !auth && (
            <div className="w-full">
              <HeaderLogin />
            </div>
          )}
          {auth && (
            <>
              <NavLink to={`/${language}/profile`} className="uppercase text-2xl my-1 px-4 tracking-widest">
                Profilom
              </NavLink>

              <NavLink to={`/${language}/orders`} className="uppercase text-2xl my-1 px-4 tracking-widest">
                Rendeléseim
              </NavLink>

              <button type="button" onClick={handleLogout} className="uppercase text-2xl my-1 px-4 tracking-widest">
                Kijelentkezés
              </button>
            </>
          )}
          <Prompt
            message={(location, action) => {
              setOpen(false);
              setActiveSubmenu('');
              return true;
            }}
          />
        </div>
      )}

      {activeSubmenu && (
        <div className="md:flex flex-col px-12 pb-5 hidden">
          {activeSubmenu ? <>{subMenus[activeSubmenu]}</> : <></>}
        </div>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  webshopMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default Header;
