import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MainPageCarouselItem from './../main-page-carousel-item/MainPageCarouselItem';

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1600,
  autoplaySpeed: 5000,
  cssEase: 'linear',
  adaptiveHeight: true,
  pauseOnFocus: false,
  pauseOnHover: false,
};

const MainPageCarousel = (props) => {
  const list = props.items.map((item) => <MainPageCarouselItem key={item.id} item={item} />);

  return <Slider {...settings}>{list}</Slider>;
};

export default MainPageCarousel;
