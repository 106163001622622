import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageContainer from './../../../shared/components/page-container/PageContainer';
import ProductDetailsFrom from './../product-details-form/ProductDetailsForm';
import ProductInfoAccordion from './../product-info-accordion/ProductInfoAccordion';
import Loader from './../../../shared/components/loader/Loader';
import ProductImageCarousel from '../product-image-carousel/ProductImageCarousel';
import ListBreadcrumb from './../../../shared/components/list-breadcrumb/ListBreadcrumb';

import { getProductById, getProductVariations, postToCart } from './../../../shared/api-path-generators';

import {
  start,
  productSuccess,
  variationsSuccess,
  finishError,
  reset,
  productSelector,
} from './../../../store/slices/productSlice';

import {
  addToCart,
  finishError as cartError,
  addToCartSuccess,
  cartKeySelector,
  cartSelector,
} from './../../../store/slices/cartSlice';

import { userSelector } from './../../../store/slices/userSlice';
import { rawShopCategoriesSelector } from './../../../store/slices/shopCategoriesSlice';

import { numberFormatter, createMarkup } from './../../../shared/helpers';

const topCategoriesToFilter = [17, 18, 19];

const isHomeware = (categories) => categories.some((item) => item.slug === 'homeware');

const Product = (props) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { product, variations, loading, error } = useSelector(productSelector);
  const { cartKey } = useSelector(cartKeySelector);
  const { loading: cartLoading, error: cartErrorState } = useSelector(cartSelector);
  const { auth, loading: userLoading } = useSelector(userSelector);
  const { categories } = useSelector(rawShopCategoriesSelector);

  const fabric = product ? product.attributes.find((item) => item.name === 'fabric').options[0] : '';

  const colors = product
    ? product.attributes.find((item) => item.name === 'color').options /* .map((col) => col.replace(' ', '-')) */
    : [];

  const productCategory = product
    ? product.categories.find((cat) => !topCategoriesToFilter.includes(cat.id))
    : undefined;

  useLayoutEffect(() => {
    const id = routeParams.productSlug.split('-')[0];
    dispatch(start({}, productSuccess, finishError, getProductById(id), 'GET'));
    return () => {
      dispatch(reset());
    };
  }, []);

  useLayoutEffect(() => {
    const id = routeParams.productSlug.split('-')[0];
    product && dispatch(start({}, variationsSuccess, finishError, getProductVariations(id), 'GET'));
  }, [product]);

  const handleAddToCart = (productData) => {
    const toPost = {
      ...productData,
      fabric,
    };
    delete toPost.quantity;
    delete toPost.color;

    const variation = variations.find((v) => v.color.includes(productData.color));

    const postCartKey = auth ? null : cartKey;

    dispatch(
      addToCart(
        {
          id: `${variation.id}`,
          quantity: `${productData.quantity ? productData.quantity : 1}`,
          variation: {
            attribute_color: productData.color,
          },
          item_data: toPost,
        },
        addToCartSuccess,
        cartError,
        postToCart(postCartKey),
        'POST',
        'Hozzáadtuk a kosaradhoz.'
      )
    );
  };

  return (
    <div className="h-full pt-16 pb-8 lg:pb-0 lg:pt-24">
      {error && <div className="border border-red-500 p-4 text-red-500 mb-4">Hiba történt</div>}
      {loading || userLoading || !product ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 xl:grid-cols-3">
          <div className="pb-8 xl:col-span-2">
            <ListBreadcrumb selectedCategory={productCategory} categories={categories} product={product} />
            <ProductImageCarousel images={product.images} />
          </div>
          <div className="px-6 lg:pl-16">
            {cartErrorState && <div className="border border-red-500 p-4 text-red-500 mb-4">Hiba történt</div>}
            <div className="pb-14">
              <h1 className="text-3xl	pb-2 font-light">{product.name}</h1>
              <h2>{numberFormatter(product.price)} HUF</h2>
            </div>
            {product.description && (
              <div className="pb-14" dangerouslySetInnerHTML={createMarkup(product.description)}></div>
            )}
            <ProductDetailsFrom
              fabric={fabric}
              options={colors}
              onSubmit={handleAddToCart}
              categories={product.categories}
              loading={cartLoading}
            />
            <ProductInfoAccordion homeware={isHomeware(product.categories)} />
          </div>
        </div>
      )}
    </div>
  );
};

Product.propTypes = {};

export default Product;
