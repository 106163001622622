import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { clearCart, finishError, finishSuccess, cartKeySelector } from './../../../../store/slices/cartSlice';

import { clearCartPath } from './../../../../shared/api-path-generators';

const CheckoutFailed = (props) => {
  const dispatch = useDispatch();
  const { cartKey } = useSelector(cartKeySelector);
  const [orderId, setOrderId] = useState(null);

  useLayoutEffect(() => {
    dispatch(clearCart(null, finishSuccess, finishError, clearCartPath(cartKey), 'POST'));
    localStorage.removeItem('cart_key');
    const orderNumber = localStorage.getItem('orderId');
    setOrderId(orderNumber);
    localStorage.removeItem('orderId');
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center px-8">
      <h1 className="text-3xl lg:text-4xl text-center">Sajnos a #{orderId} számú rendelésed sikertelen volt. </h1>
      <h2 className="text-lg lg:text-2xl pt-4">
        Kérjük vedd fel velünk a kapcsolatot az{' '}
        <a
          className="hover:underline"
          href={`mailto:info@elischer-r.hu?subject=${orderId + 'rendelés'}`}
          target="_blank"
          rel="noreferrer"
        >
          info@elischer-r.hu
        </a>{' '}
        e-mail címen, és a levél tárgyához írd be a rendelési számod. Köszönjük!
      </h2>
    </div>
  );
};

CheckoutFailed.propTypes = {};

export default CheckoutFailed;
