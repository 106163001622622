const defaultMeasurements = [
  {
    label: 'Magassag',
    value: 'magassag',
  },
];

const homeWare = [
  {
    label: 'Mennyiseg',
    value: 'quantity',
  },
];

const topMeasurements = [
  {
    label: 'Vallszelesseg',
    value: 'Vallszelesseg',
  },
  {
    label: 'Mellboseg',
    value: 'mellboseg',
  },
];

const bottomMeasurements = [
  {
    label: 'Csipoboseg',
    value: 'csipoboseg',
  },
  {
    label: 'Derekboseg',
    value: 'derekboseg',
  },
];

// wordpress  category ids for the required category
const topCategoriesToFilter = [17, 18, 19];

const topCategoryIds = [42, 43, 78, 79];

const bottomCategoryIds = [20, 45, 80];

const bothCategoryIds = [44, 21, 62];

const homewareId = [46, 47, 48, 49, 50];

const getRequiredMeasurements = (t, categories) => {
  const { id: categoryId } = categories.find((cat) => !topCategoriesToFilter.includes(cat.id));

  let measurements;

  if (homewareId.includes(categoryId)) {
    measurements = [].concat(homeWare);
  } else {
    measurements = [].concat(defaultMeasurements);
  }

  if (bothCategoryIds.includes(categoryId)) {
    measurements = measurements.concat(topMeasurements, bottomMeasurements);
  } else if (topCategoryIds.includes(categoryId)) {
    measurements = measurements.concat(topMeasurements);
  } else if (bottomCategoryIds.includes(categoryId)) {
    measurements = measurements.concat(bottomMeasurements);
  }

  return measurements
    .map((item) => ({
      ...item,
      label: t(item.label),
    }))
    .reverse();
};

export { defaultMeasurements, topMeasurements, bottomMeasurements, getRequiredMeasurements };
