import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import TextField from './../../../shared/components/text-field/TextField';

const guestUserValidationSchema = Yup.object({
  email: Yup.string().email('E-mail is not correct').required('E-mail is required'),
  name: Yup.string().required('Name is required'),
});

const SignupForm = ({ handleSubmit }) => {
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <div className="py-16 flex flex-col lg:flex-row justify-center items-center">
          <div className="text-2xl lg:pr-3 pb-6 lg:pb-0">Iratkozz fel hírlevelünkre</div>
          <div className="w-full lg:w-auto px-6">
            <Form>
              <div className="flex flex-col lg:flex-row items-center lg:space-x-3">
                <div className="w-full lg:w-72 pb-6 lg:pb-0">
                  <Field name="name" component={TextField} placeholder="Név" height="h-14" />
                </div>
                <div className="w-full lg:w-72 pb-6 lg:pb-0">
                  <Field name="email" component={TextField} placeholder="E-mail cím" height="h-14" />
                </div>
                <button
                  className="border border-green text-green hover:bg-green hover:text-white h-14 px-6 w-full lg:w-60"
                  type="submit"
                >
                  Feliratkozás
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

SignupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
