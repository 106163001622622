import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckoutCartListItem from './../checkout-cart-list-item/CheckoutCartListItem';

const CheckoutCartList = ({ items }) => {
  const { t } = useTranslation();

  const list = items.map((item) => <CheckoutCartListItem item={item} key={item.id} />);

  return (
    <>
      <div className="w-full hidden md:flex flex-row pb-4">
        <div className="w-4/12 text-left font-bold">{t('product')}</div>
        <div className="w-3/12 text-right font-bold">{t('measurements')}</div>
        <div className="w-2/12 text-center font-bold">{t('color')}</div>
        <div className="w-1/12 text-right font-bold">{t('quantity')}</div>
        <div className="w-2/12 text-right font-bold">{t('price')}</div>
      </div>
      <div className="w-full flex flex-col">{list}</div>
    </>
  );
};

CheckoutCartList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CheckoutCartList;
