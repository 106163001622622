import React, { useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { AppContext } from './../../components/app/AppContext';

import MainPage from './../../components/main-page/main-page/MainPage';
import StaticPage from '../../components/main-page/static-page/StaticPage';
import Webshop from './../../components/webshop/webshop/Webshop';
import Product from './../../components/webshop/product/Product';
import Cart from './../../components/webshop/cart/Cart';
import Checkout from '../../components/webshop/checkout/checkout/Checkout';
import RedirectHandler from '../components/redirect-handler/RedirectHandler';
import CheckoutSuccess from '../../components/webshop/checkout/checkout-success/CheckoutSuccess';
import CheckoutFailed from './../../components/webshop/checkout/checkout-failed/CheckoutFailed';
import Contact from '../../components/contact-page/contact/Contact';
import Profile from './../../components/profile-page/profile/Profile';
import OrdersPage from './../../components/orders-page/orders-page/OrdersPage';
import OurStoryPage from './../../components/our-story-page/OurStoryPage';
import SustainabilityPage from './../../components/sustainability-page/SustainabilityPage';
/* import OrderDetails from './../../components/order-details/OrderDetails'; */

import FullScreenModal from './../../shared/components/full-screen-modal/FullScreenModal';

const RouterContainer = (props) => {
  const { language } = useContext(AppContext);
  let location = useLocation();

  let background = location.state && location.state.background;

  return (
    <div className=" flex-grow">
      <Switch location={background || location}>
        <Route path={`/:lang(en|hu)/redirect`} component={RedirectHandler} />
        <Route path={`/:lang(en|hu)/`} exact component={MainPage} />
        <Route path={`/:lang(en|hu)/shop/cart`} component={Cart} />
        <Route path={`/:lang(en|hu)/shop/checkout/success`} component={CheckoutSuccess} />
        <Route path={`/:lang(en|hu)/shop/checkout/failed`} component={CheckoutFailed} />
        <Route path={`/:lang(en|hu)/shop/checkout`} component={Checkout} />
        <Route path={`/:lang(en|hu)/shop/product/:productSlug`} component={Product} />
        <Route path={`/:lang(en|hu)/shop/:category?/:subcategory?`} component={Webshop} />
        <Route path={`/:lang(en|hu)/contact`} exact component={Contact} />
        <Route path={`/:lang(en|hu)/profile`} exact component={Profile} />
        <Route path={`/:lang(en|hu)/orders`} exact component={OrdersPage} />
        {/* <Route path={`/:lang(en|hu)/orders/:orderId`} exact component={OrderDetails} /> */}
        <Route path={`/:lang(en|hu)/fenntarthatosag`} exact component={SustainabilityPage} />
        <Route path={`/:lang(en|hu)/our-story`} exact component={OurStoryPage} />
        <Route path={`/:lang(en|hu)/:slug`} exact component={StaticPage} />
        <Redirect to={`/${language}/`} />
      </Switch>
      {background && <Route path={`/:lang(en|hu)/dialog/register`} exact component={FullScreenModal} />}
    </div>
  );
};

RouterContainer.propTypes = {};

export default RouterContainer;
