import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearCart, finishError, finishSuccess, cartKeySelector } from './../../../../store/slices/cartSlice';
import { orderSelector } from './../../../../store/slices/orderSlice';

import { clearCartPath } from './../../../../shared/api-path-generators';

import TransferData from './../transfer-data/TransferData';

const CheckoutSuccess = (props) => {
  const dispatch = useDispatch();
  const { cartKey } = useSelector(cartKeySelector);
  const { order } = useSelector(orderSelector);

  useLayoutEffect(() => {
    dispatch(clearCart(null, finishSuccess, finishError, clearCartPath(cartKey), 'POST'));
    localStorage.removeItem('cart_key');
    localStorage.removeItem('orderId');
    localStorage.removeItem('currentOrder');
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-4xl text-center">Köszönjük a rendelésed!</h1>
      <h2 className="text-center text-2xl pt-4">
        Minden termékünket gondosan, kézzel, és sok-sok szeretettel készítjük el neked.
      </h2>
      {order && order.payment_method === 'bacs' && (
        <>
          <h2 className="text-2xl pt-4">
            Kérjük vedd figyelembe, hogy rendelésed feldolgozását az utalás beérkezéséig nem tudjuk elkezdeni.
          </h2>
          <div className="pt-4">
            <h3 className="text-lg pb-2">Az utaláshoz szükséges információk:</h3>
            <p>Rendelési szám: #{order.id}</p>
            <TransferData />
          </div>
        </>
      )}
    </div>
  );
};

CheckoutSuccess.propTypes = {};

export default CheckoutSuccess;
