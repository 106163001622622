import { call, put, all, select } from 'redux-saga/effects';
import { httpRequest, authedHttpRequest } from './api-calls';

import { userSelector } from './../slices/userSlice';

import { clone } from 'ramda';

export default function* orderWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type },
  } = action;

  const { auth } = yield select(userSelector);

  let postObject = clone(payload) || {};

  let apiCall = httpRequest(url, type);

  if (auth) {
    apiCall = authedHttpRequest(url, type);
    postObject.token = localStorage.getItem('elischer-token');
    postObject.data = clone(payload);
  }

  try {
    const { data } = yield call(apiCall, postObject);
    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
