import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: undefined,
  error: false,
};

const shippingMethodsSlice = createGenericSlice({
  name: 'shippingMethods',
  initialState,
  reducers: {},
});

const { actions, reducer } = shippingMethodsSlice;

const shippingMethodsState = (state) => state.shippingMethods;

export const shippingMethodsSelector = createDraftSafeSelector(
  shippingMethodsState,
  (state) => ({
    shippingMethods: state.data,
    loading: state.loading,
    error: state.error,
  })
);

export const { start, finishError, finishSuccess } = actions;

export default reducer;
