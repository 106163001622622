import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from './../../../components/app/AppContext';

import { ReactComponent as InstagramIcon } from './../../images/instagram-brands.svg';
import { ReactComponent as FacebookIcon } from './../../images/facebook-square-brands.svg';
import { ReactComponent as BarionStrip } from './barion-card-strip-intl.svg';
import { ReactComponent as GSLogo } from './global-organic-textile-standard-gots-vector-logo.svg';

const Footer = (props) => {
  const { language } = useContext(AppContext);

  return (
    <footer className="px-8 py-4 text-xs flex justify-between items-start xl:items-center flex-col xl:flex-row">
      <div className="pb-2 xl:pb-0">© 2021 ELISCHER R. All rights reserved. Elischer R is a woman-owned business.</div>
      <div className="flex items-start xl:items-center flex-col xl:flex-row">
        <div className="xl:space-x-2 xl:space-y-0 space-y-2 pr-2 flex flex-col xl:flex-row">
          <Link to={`/${language}/aszf`}>ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK</Link>
          <Link to={`/${language}/adatkezelesi-tajekoztato`}>ADATKEZELÉSI TÁJÉKOZTATÓ</Link>
        </div>
        {/* <BarionStrip className="h-6 mr-2" /> */}
        <div className="flex flex-row pt-2 xl:pt-0">
          <a href="https://global-standard.org" target="_blank" rel="noopener noreferrer" className="h-8 w-8 mr-2">
            <GSLogo className="h-8 w-8" />
          </a>
          <a href="https://www.instagram.com/elischer_r/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon className="h-8 w-8 mr-2" />
          </a>
          <a href="https://www.facebook.com/elischerR1" target="_blank" rel="noopener noreferrer">
            <FacebookIcon className="h-8 w-8" />
          </a>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
