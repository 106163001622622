/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import RoundLoader from '../../../../shared/components/round-loader/RoundLoader';
import NestedTextField from '../../../../shared/components/nested-text-field/NestedTextField';

const fieldMap = (t) => (key) => (field) =>
  (
    <div key={field.name} className="flex justify-between items-center">
      <p>{t(field.label)}</p>
      <Field
        component={NestedTextField}
        name={`${key}.${field.name}`}
        placeholder={t(field.label)}
        // className="border my-1 p-2 border-green w-7/12"
      />
    </div>
  );

const AddressForm = ({ formFields, values, loading }) => {
  const { t } = useTranslation();
  const { billing, shipping, same_address } = formFields;

  const billingFields = billing.map(fieldMap(t)('billing'));
  const shippingFields = shipping.map(fieldMap(t)('shipping'));

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4 w-full pt-8 pb-6 justify-between">
        <div className="pb-8">
          <h3 className="text-xl">{t('billing address')}</h3>
          {billingFields}
          <label className="inline-flex items-center mt-3">
            <Field name={same_address.name} type="checkbox" className="form-checkbox h-5 w-5 text-gray-600 bg-green" />
            <span className="ml-2">{t('Shipping address is the same')}</span>
          </label>
        </div>
        <div className="">
          {!values.same_address && (
            <>
              <h3 className="text-xl">{t('shipping address')}</h3>
              {shippingFields}
            </>
          )}
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="border border-green py-4 px-6 flex items-center justify-center w-full lg:w-60 text-green hover:bg-green hover:text-white"
          disabled={loading}
        >
          {loading ? <RoundLoader /> : t('next')}
        </button>
      </div>
    </>
  );
};

AddressForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  values: PropTypes.object,
  loading: PropTypes.bool,
};

export default AddressForm;
