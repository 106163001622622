import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RoundLoader from './../../../shared/components/round-loader/RoundLoader';
import HeartIcon from './../../../shared/components/heart-icon/HeartIcon';
import FabricColorPicker from '../../../shared/components/fabric-color-picker/FabricColorPicker';
import TextareaField from '../../../shared/components/text-field/TextField';

import { getRequiredMeasurements } from './../../../shared/measurments';

const generateInitialValues = (measurements) => {
  const initialValues = {
    color: '',
  };

  const initalMeassurements = measurements.map((m) => m.value);

  initalMeassurements.forEach((item) => {
    initialValues[item] = '';
  });

  return initialValues;
};

const getPlaceholder = (value, items) => {
  return items.find((i) => i.value === value).label;
};

const generateValidationSchema = (fields, t) => {
  const validationSchema = {
    color: Yup.string().required(t('Kerlek valasz szint')),
  };

  fields.forEach((field) => {
    validationSchema[field.value] = Yup.number()
      .max(300, t('tul nagy'))
      .typeError(t('csak szamokat adjon meg'))
      .required(t('kotelezo megadni'));
  });

  return Yup.object().shape(validationSchema);
};

const HOMEWARE_PARENT_ID = 18;
const HOMEWARE_SET_ID = 62;

const ProductDetailsForm = ({ onSubmit, fabric, options, categories, loading }) => {
  const { t } = useTranslation();

  const measurements = getRequiredMeasurements(t, categories);

  const measurementsDataRequired =
    !categories.find((cat) => cat.id === HOMEWARE_PARENT_ID) || categories.find((cat) => cat.id === HOMEWARE_SET_ID);

  const validationSchema = generateValidationSchema(measurements, t);

  const handleSubmit = (values, { resetForm }) => {
    resetForm();
    onSubmit(values);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={generateInitialValues(measurements)}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, dirty, errors, values }) => (
        <Form className="h-6/6">
          <div className="flex flex-col justify-between">
            {measurementsDataRequired && (
              <>
                <h2 className="border-b border-green pb-2">{t('sizes')}</h2>
                <div className="text-sm py-4">
                  Méretvételi útmutató
                  <ul className="text-xs pt-2">
                    <li>Vállszélesség - a hátadon vízszintesen a vállcsúcstól vállcsúcsig mérd.</li>
                    <li>Mellbőség - a mell legerősebb részén át vízszintesen körben mérd.</li>
                    <li>Derékbőség - kb. 2,5 cm-rel a köldök felett vízszintesen körben mérd.</li>
                    <li>Csípőbőség - A csípőcsont legerősebb pontján át vízszintesen körben mérd.</li>
                  </ul>
                </div>
              </>
            )}
            <div>
              {Object.keys(values)
                .filter((item) => item !== 'color')
                .map((item) => (
                  <div key={item} className="py-1">
                    <Field name={item} component={TextareaField} placeholder={getPlaceholder(item, measurements)} />
                  </div>
                ))}
            </div>
            <h2 className="border-b border-green mb-4 mt-10 pb-2">{t('colors')}</h2>
            <Field name="color" fabric={fabric} options={options} component={FabricColorPicker} />
            <div className="py-8 flex items-center space-x-2">
              {/* <Button type="submit" text="Kosárba" disabled={dirty} /> */}
              <button
                type="submit"
                className="border border-green py-8 md:py-2 px-4 flex items-center justify-center h-10 text-green hover:bg-green hover:text-white w-full"
                disabled={loading}
              >
                {loading ? <RoundLoader /> : t('add to cart')}
              </button>
              {/* <button type="button" className="border border-green px-2 text-green hover:text-red-500 h-10">
                <HeartIcon className="h-6 w-6" />
              </button> */}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ProductDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fabric: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default ProductDetailsForm;
