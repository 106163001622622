import { all } from 'redux-saga/effects';
import { latestWatcher } from './watcher.sagas';

import { workerSaga, authedWorkerSaga, barionWorkerSaga } from './worker.saga';
import wcWorkerSaga from './wc-worker.saga';
import { loginWorkerSaga, validateWorkerSaga, logoutWorkerSaga } from './login-worker.saga';
import deleteWorkerSaga from './delete-worker.saga';
import orderWorkerSaga from './order-worker.saga';
import { cartWorkerSaga } from './cart-worker.saga';

import { start as sliderStart } from './../slices/sliderSlice';
import { start as pageStart } from './../slices/pageSlice';
import { start as categoriesStart } from './../slices/shopCategoriesSlice';
import { start as productsStart } from './../slices/productsSlice';
import { start as productStart } from './../slices/productSlice';
import { start as colorStart } from './../slices/colorsSlice';
import { addToCart, getCart, removeFromCart, clearCart } from './../slices/cartSlice';
import { start as orderStart } from './../slices/orderSlice';
import { login, validate, register, logout, updateProfile } from './../slices/userSlice';
import { start as shippingMethodsStart } from './../slices/shippingMethodsSlice';
import { start as mailStart } from '../slices/mailSlice';
import { start as customerOrdersStart } from './../slices/customerOrdersSlice';

const sagasToWatch = [
  {
    actionType: sliderStart.type,
    worker: workerSaga,
  },
  {
    actionType: pageStart.type,
    worker: workerSaga,
  },
  {
    actionType: categoriesStart.type,
    worker: wcWorkerSaga,
  },
  {
    actionType: productsStart.type,
    worker: wcWorkerSaga,
  },
  {
    actionType: productStart.type,
    worker: wcWorkerSaga,
  },
  {
    actionType: addToCart.type,
    worker: cartWorkerSaga,
  },
  {
    actionType: getCart.type,
    worker: workerSaga,
  },
  {
    actionType: colorStart.type,
    worker: workerSaga,
  },
  {
    actionType: orderStart.type,
    worker: orderWorkerSaga,
  },
  {
    actionType: login.type,
    worker: loginWorkerSaga,
  },
  {
    actionType: logout.type,
    worker: logoutWorkerSaga,
  },
  {
    actionType: validate.type,
    worker: validateWorkerSaga,
  },
  {
    actionType: removeFromCart.type,
    worker: deleteWorkerSaga,
  },
  {
    actionType: clearCart.type,
    worker: deleteWorkerSaga,
  },
  {
    actionType: shippingMethodsStart.type,
    worker: wcWorkerSaga,
  },
  {
    actionType: mailStart.type,
    worker: workerSaga,
  },
  {
    actionType: register.type,
    worker: workerSaga,
  },
  {
    actionType: customerOrdersStart.type,
    worker: authedWorkerSaga,
  },
  {
    actionType: updateProfile.type,
    worker: authedWorkerSaga,
  },
];

function* rootSaga() {
  yield all([...sagasToWatch.map((item) => latestWatcher(item.actionType, item.worker)())]);
}

export default rootSaga;
