import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { start, finishSuccess, finishError, sliderSelector } from './../../../store/slices/sliderSlice';

import { getSlider } from './../../../shared/api-path-generators';

import MainPageCarousel from './../main-page-carousel/MainPageCarousel';
import WebshopCategoryLink from './../webshop-category-link/WebshopCategoryLink';
import Subscription from '../main-page-subscription/MainPageSubscription';
import MainPageLogo from './../main-page-logo/MainPageLogo';
import Loader from './../../../shared/components/loader/Loader';

import homeware from './../../../shared/images/home.jpg';
import women from './../../../shared/images/woman.jpg';
import men from './../../../shared/images/man.jpg';

const webshopLinks = [
  {
    image: homeware,
    path: 'homeware',
  },
  {
    image: women,
    path: 'women',
  },
  {
    image: men,
    path: 'men',
  },
];

const generateCategoryUrl = (lang, category) => `/${lang}/shop/${category}`;

const MainPage = (props) => {
  const dispatch = useDispatch();
  const { items, loading, error } = useSelector(sliderSelector);

  useLayoutEffect(() => {
    !items && dispatch(start(null, finishSuccess, finishError, getSlider(), 'GET'));
  }, [dispatch]);

  const links = webshopLinks.map((item) => (
    <WebshopCategoryLink key={item.path} path={generateCategoryUrl('hu', item.path)} imageSource={item.image} />
  ));

  return (
    <>
      {error && <div>Error</div>}
      {loading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && items && (
        <>
          <div className="relative h-screen">
            <MainPageCarousel items={items} />
            <MainPageLogo />
          </div>
          <Subscription />
          <div className="flex flex-col md:flex-row justify-around md:space-x-2 space-y-2 md:space-y-0">{links}</div>
        </>
      )}
    </>
  );
};

export default MainPage;
