import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AppContext } from './../../../components/app/AppContext';

const home = {
  label: 'Kezdőlap',
  slug: '',
};

const generateBreadcrumbs = (id, categories) => {
  const cat = categories.find((item) => item.id === id);

  if (cat.parent) {
    return [cat, ...generateBreadcrumbs(cat.parent, categories)];
  } else {
    return [cat];
  }
};

const ListBreadcrumb = ({ selectedCategory, categories, product }) => {
  const { language } = useContext(AppContext);
  const bc = [
    ...generateBreadcrumbs(selectedCategory.id, categories).map((cat) => ({ label: cat.name, slug: cat.slug })),
    home,
  ].reverse();

  const breadcrumb = bc.map((item, index) => {
    const url = `/${language}/shop/${bc[index - 1] && bc[index - 1].slug ? bc[index - 1].slug + '/' : ''}${item.slug}`;

    return (
      <React.Fragment key={item.label}>
        <div className={`px-2 uppercase ${item.slug === selectedCategory.slug && !product && 'font-bold'}`}>
          <Link to={index ? url : '/'}>{item.label}</Link>
        </div>
        {index < bc.length - 1 && '/'}
      </React.Fragment>
    );
  });

  return (
    <div className="flex pb-2 text-xs">
      {breadcrumb}
      {product && (
        <>
          {' '}
          / <div className={`px-2 uppercase font-bold`}>{product.name}</div>
        </>
      )}
    </div>
  );
};

ListBreadcrumb.propTypes = {
  selectedCategory: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  product: PropTypes.object,
};

export default ListBreadcrumb;
