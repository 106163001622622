import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { pageSelector } from './../../store/slices/pageSlice';
import PageContainer from './../../shared/components/page-container/PageContainer';

import aliz from './Aliz.jpg';
import reka1 from './Reka-1.jpg';
import reka2 from './Reka-2.jpg';

export const createMarkup = (data) => ({ __html: data });

const pics = [
  {
    name: 'Elischer Réka',
    img: reka1,
    title: 'Designer & dressmaker',
  },
  {
    name: 'Ivánfi Aliz',
    img: aliz,
    title: 'Brand creative',
  },
  {
    name: 'Kongsaysak Réka',
    img: reka2,
    title: 'Graphic designer',
  },
];

const OurStoryPage = (props) => {
  const { pages } = useSelector(pageSelector);

  const page = useMemo(() => {
    return pages.find((page) => page.slug === 'our-story');
  }, [pages]);

  return (
    <PageContainer>
      <div className="mx-auto lg:w-8/12 px-8 lg:px-0">
        <h1 className="text-2xl text-center lg:text-left">{page.title.rendered}</h1>
        <div className="flex flex-col md:flex-row py-12">
          {pics.map((pic) => (
            <div key={pic.name} className="lg:w-4/12 p-3">
              <img src={pic.img} alt={pic.name} className="rounded-full" />
              <div className="text-center text-xl pt-2">{pic.name}</div>
              <div className="text-center text-xs">{pic.title}</div>
            </div>
          ))}
        </div>
        <div dangerouslySetInnerHTML={createMarkup(page.content.rendered)}></div>
      </div>
    </PageContainer>
  );
};

OurStoryPage.propTypes = {};

export default OurStoryPage;
