import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated, config } from 'react-spring';
import { useHistory } from 'react-router-dom';

import RegisterCustomer from './../register-customer/RegisterCustomer';
import XICon from './../x-icon/XIcon';

const FullScreenModal = (props) => {
  const history = useHistory();
  const [show, set] = useState(true);

  const transitions = useTransition(show, {
    from: { opacity: 0, display: 'none' },
    enter: { opacity: 1, display: 'block' },
    leave: { opacity: 0, display: 'none' },
    reverse: show,
    config: config.molasses,
  });

  const handleClose = () => {
    history.goBack();
  };

  return transitions(
    (styles, item) =>
      item && (
        <animated.div style={styles} className="absolute w-full h-full top-0 left-0 z-10">
          <div className="w-full h-full bg-white flex items-center justify-center">
            <button className="absolute top-0 right-0" onClick={handleClose}>
              <XICon className="w-12 h-12 stroke-current text-green" />
            </button>
            <RegisterCustomer />
          </div>
        </animated.div>
      )
  );
};

FullScreenModal.propTypes = {};

export default FullScreenModal;
