import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { colorsSelector } from './../../../../store/slices/colorsSlice';

import { fabricCategoryId } from './../../../../shared/color-category-ids';

import { defaultMeasurements, topMeasurements, bottomMeasurements } from './../../../../shared/measurments';
import { convertToArray, getColor, trimColor } from './../../cart-list-item/CartListItem';

import { numberFormatter } from '../../../../shared/helpers';

const measurements = defaultMeasurements.concat(topMeasurements, bottomMeasurements);

const CheckoutCartListItem = ({ item }) => {
  const { t } = useTranslation();
  const { colors } = useSelector(colorsSelector);
  const fabric = item.cart_item_data['fabric'];

  const color = item.meta.variation.color;
  const matchedColorName = trimColor(color);

  const itemColor = getColor(colors, fabricCategoryId[fabric], matchedColorName[1]);

  const itemData = convertToArray(item.cart_item_data)
    .filter((i) => i.label !== 'fabric')
    .map((data) => {
      const measurement = measurements.find((i) => i.value === data.label);

      return (
        <li key={data.label} className="flex justify-between">
          <div>{t(measurement.label)}:</div> <div>{data.value} cm</div>
        </li>
      );
    });

  return (
    <div className="flex flex-col md:flex-row w-full pb-2 border-b mb-2">
      <div className="w-full md:w-4/12 flex items-center">{item.title}</div>
      <div className="w-full flex md:hidden py-2">
        <div className="text-xs w-6/12">
          <ul>
            {itemData}
            <li className="flex justify-between">
              <div>Mennyiség:</div> <div>{item.quantity.value} db</div>
            </li>
          </ul>
        </div>
        <div className="pl-4 w-6/12">
          <img className="rounded-full w-10 h-10" src={itemColor['_embedded']['wp:featuredmedia'][0].source_url} />
        </div>
      </div>
      <div className="w-3/12 text-xs hidden md:block">
        <ul>{itemData}</ul>
      </div>
      <div className="w-2/12 text-center hidden md:block">
        <div className="flex justify-center">
          <img className="rounded-full w-10 h-10" src={itemColor['_embedded']['wp:featuredmedia'][0].source_url} />
        </div>
      </div>
      <div className="w-1/12 text-right items-center hidden md:flex">{item.quantity.value}</div>
      <div className="w-full md:w-2/12 text-right flex items-center justify-end">
        {numberFormatter(item.totals.total)} HUF
      </div>
    </div>
  );
};

CheckoutCartListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CheckoutCartListItem;
