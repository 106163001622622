import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cartSelector, removeFromCart, finishError, finishSuccess } from './../../../store/slices/cartSlice';

import { defaultMeasurements, topMeasurements, bottomMeasurements } from './../../../shared/measurments';

import { numberFormatter } from './../../../shared/helpers';

import XIcon from '../../../shared/components/x-icon/XIcon';
import RoundLoader from './../../../shared/components/round-loader/RoundLoader';

import { removeItemFromCart } from './../../../shared/api-path-generators';

import { colorsSelector } from '../../../store/slices/colorsSlice';

import { fabricCategoryId } from '../../../shared/color-category-ids';

const measurements = defaultMeasurements.concat(topMeasurements, bottomMeasurements);

export const convertToArray = (dataObject) => {
  let array = [];

  for (const [key, value] of Object.entries(dataObject)) {
    array.push({
      label: key,
      value,
    });
  }
  return array;
};

export const getColor = (colors, fabricId, colorName) => {
  const fabricColors = colors.filter((color) => color.categories.includes(fabricId));
  return fabricColors.find((color) => {
    return color.title.rendered === colorName;
  });
};

const regex = /\](.*?)\[/;

export const trimColor = (color) => color.match(regex);

const CartListItem = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useSelector(colorsSelector);
  const { cart, error } = useSelector(cartSelector);
  const fabric = item.cart_item_data['fabric'];

  const color = item.meta.variation.color;
  const matchedColorName = trimColor(color);

  const itemColor = getColor(colors, fabricCategoryId[fabric], matchedColorName[1]);

  const itemData = convertToArray(item.cart_item_data)
    .filter((i) => i.label !== 'fabric')
    .map((data) => {
      const measurement = measurements.find((i) => i.value === data.label);
      return (
        <li key={data.label} className="flex justify-between">
          <div>{t(measurement.label)}:</div> <div>{data.value} cm</div>
        </li>
      );
    });

  const handleRemoveItem = () => {
    setLoading(true);
    dispatch(
      removeFromCart(
        null,
        finishSuccess,
        finishError,
        removeItemFromCart(cart.cart_key, item.item_key),
        'DELETE',
        'Sikeresen eltvávolítottuk az elemet a kosaradból.'
      )
    );
  };

  return (
    <div className="border-b w-full flex flex-col md:flex-row md:items-center">
      <div className="w-full lg:w-5/12 flex py-4 items-start">
        <div className="w-36 h-36 lg:w-44 lg:h-44 overflow-hidden">
          <img src={item.featured_image} className="object-scale-down" />
        </div>
        <div className="px-4">
          <div className="text-base lg:text-lg pb-2">{item.title}</div>
          <div className="lg:hidden">
            <img
              className="rounded-full w-14 h-14 lg:w-14 lg:h-14"
              src={itemColor['_embedded']['wp:featuredmedia'][0].source_url}
            />
          </div>
        </div>
        <button
          type="button"
          className="p-1 border-green border md:hidden flex items-center justify-center"
          onClick={handleRemoveItem}
        >
          {!loading ? (
            <div className="w-4 h-4 flex items-center justify-center">
              <RoundLoader />
            </div>
          ) : (
            <XIcon className="h-4 w-4 text-green" />
          )}
        </button>
      </div>
      <div className="w-full lg:w-2/12 text-sm">
        <ul>
          {itemData}
          <li className="hidden md:flex justify-between">
            <div>Mennyiség:</div> <div>{item.quantity.value} db</div>
          </li>
        </ul>
      </div>

      <div className="w-2/12 hidden lg:flex justify-center">
        <img className="rounded-full w-14 h-14" src={itemColor['_embedded']['wp:featuredmedia'][0].source_url} />
      </div>

      <div className="text-sm flex md:hidden justify-between">
        <div>Mennyiség:</div> <div>{item.quantity.value} db</div>
      </div>

      <div className="w-full lg:w-1/12 hidden md:flex justify-end items-center">
        <div className="mr-4">{item.quantity.value}</div>
        <button type="button" className="p-1 border-green border" onClick={handleRemoveItem}>
          {loading ? (
            <div className="w-6 h-6 flex items-center justify-center">
              <RoundLoader />
            </div>
          ) : (
            <XIcon className="h-6 w-6 text-green" />
          )}
        </button>
      </div>

      <div className="w-full lg:hidden">
        {/* <button type="button" className="p-1 border-green border" onClick={handleRemoveItem}>
          <XIcon className="h-6 w-6 text-green" />
        </button> */}
        <div className="w-full lg:w-2/12 text-right py-2">{numberFormatter(item.price)} HUF</div>
      </div>
      <div className="w-full hidden lg:block lg:w-2/12 text-right py-2">{numberFormatter(item.price)} HUF</div>
    </div>
  );
};

CartListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CartListItem;
