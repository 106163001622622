import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CheckIcons from './../check-icon/CheckIcon';

import { colorsSelector } from '../../../store/slices/colorsSlice';

import { fabricCategoryId } from '../../color-category-ids';

const FabricColorPicker = ({
  field,
  form: { touched, errors, /* handleChange */ setFieldValue, setTouched },
  fabric,
  options,
  ...props
}) => {
  const { colors } = useSelector(colorsSelector);

  const handleColorSelect = (color) => {
    if (color === field.value) {
      setFieldValue(field.name, '');
    } else {
      setFieldValue(field.name, color);
    }
  };

  const displayError = touched[field.name] && errors[field.name];
  const currentColors = colors
    .filter((item) => item.categories.includes(fabricCategoryId[fabric]))
    .filter((item) => {
      return options.includes(item.title.rendered);
    })
    .map((color) => (
      <div
        key={color.title.rendered}
        className="flex justify-center relative"
        onClick={() => handleColorSelect(color.title.rendered)}
      >
        {field.value && field.value === color.title.rendered && (
          <div className="absolute h-full w-full flex items-center justify-center">
            <CheckIcons className="text-green w-12 h-12" />
          </div>
        )}
        <img
          className={`rounded-full w-16 h-16 md:w-24 md:h-24 lg:w-14 lg:h-14 cursor-pointer`}
          src={color['_embedded']['wp:featuredmedia'][0].source_url}
        />
      </div>
    ));

  return (
    <div>
      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-6 gap-4 py-4">{currentColors}</div>
      {displayError && <p className="text-sm text-red-500">{errors[field.name]}</p>}
    </div>
  );
};

FabricColorPicker.propTypes = {
  fabric: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: PropTypes.object,
  field: PropTypes.object,
};

export default FabricColorPicker;
