import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

const Icon = (props) => {
  const [flip, set] = useState(false);
  const pathRef = useRef();
  const [totalLength, setTotalLength] = useState(null);
  const animatedStyle = useSpring({
    strokeDasharray: totalLength,
    from: {
      strokeDashoffset: totalLength,
    },
    strokeDashoffset: 0,
    reset: true,
    reverse: flip,
    config: { mass: 1, tension: 210, friction: 160 },
    onRest: () => set(!flip),
  });

  useEffect(() => {
    setTotalLength(pathRef.current.getTotalLength());
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="-30 -10 843.26 646.77"
    >
      {totalLength ? (
        <animated.path
          style={animatedStyle}
          stroke="#000000" // defines the colour of the "line"
          strokeWidth="14"
          fill="none"
          ref={pathRef}
          d="M0 121.57l50.72-30.42c2.83 5.21 225.78 389.25 228.68 394q50.51 83.57 115.13 83.56a118.35 118.35 0 0056.39-14q26.37-14 40.17-37.52a97.18 97.18 0 0013.78-49.89q0-30-20.27-58.83-28-39.75-102.25-95.74-74.62-56.39-92.89-81.54Q257.81 189 257.81 140q0-39 18.67-71T329 18.46A151.37 151.37 0 01402.64 0a156.92 156.92 0 0178.9 20.89q32.83 18.69 69.05 65.4c2.86 3.7 249.78 323.29 252.67 327.25l-48.68 36.92c-3.22-4.28-250.41-324.1-253.49-328Q473.48 88 453.15 75.86a99 99 0 00-51.72-14.2q-36.12 0-59 21.92t-22.91 53.94a91.75 91.75 0 008.11 37.73q8.12 18.27 29.62 39.76 11.76 11.37 77.08 59.64 77.48 57.19 106.29 101.82t28.8 89.65q0 64.92-49.3 112.78t-119.91 47.87q-54.37 0-98.59-29-39.78-26.1-74-83.9C225.09 509.58 2.49 126.13 0 121.57z"
        ></animated.path>
      ) : (
        <path
          stroke="none" // defines the colour of the "line"
          fill="none"
          ref={pathRef}
          d="M0 121.57l50.72-30.42c2.83 5.21 225.78 389.25 228.68 394q50.51 83.57 115.13 83.56a118.35 118.35 0 0056.39-14q26.37-14 40.17-37.52a97.18 97.18 0 0013.78-49.89q0-30-20.27-58.83-28-39.75-102.25-95.74-74.62-56.39-92.89-81.54Q257.81 189 257.81 140q0-39 18.67-71T329 18.46A151.37 151.37 0 01402.64 0a156.92 156.92 0 0178.9 20.89q32.83 18.69 69.05 65.4c2.86 3.7 249.78 323.29 252.67 327.25l-48.68 36.92c-3.22-4.28-250.41-324.1-253.49-328Q473.48 88 453.15 75.86a99 99 0 00-51.72-14.2q-36.12 0-59 21.92t-22.91 53.94a91.75 91.75 0 008.11 37.73q8.12 18.27 29.62 39.76 11.76 11.37 77.08 59.64 77.48 57.19 106.29 101.82t28.8 89.65q0 64.92-49.3 112.78t-119.91 47.87q-54.37 0-98.59-29-39.78-26.1-74-83.9C225.09 509.58 2.49 126.13 0 121.57z"
        ></path>
      )}
    </svg>
  );
};

Icon.propTypes = {
  toggle: PropTypes.bool,
};

const Loader = (props) => {
  return (
    <div className="w-48 h-48 p-4">
      <Icon />
    </div>
  );
};

Loader.propTypes = {};

export default Loader;
