import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from './../../app/AppContext';

import { ReactComponent as Logo } from './../../../shared/images/typo-logo2.svg';

const MainPageLogo = (props) => {
  const { language } = useContext(AppContext);

  return (
    <div className="absolute  w-full h-full top-0 left-0 flex flex-col items-center justify-center px-6 mg:px-0">
      <div className="w-5/12">
        <Logo className="text-white fill-current" />
      </div>
      <p className="text-white py-10 text-base lg:text-xl font-light text-center">
        <span className="uppercase font-medium text-lg lg:text-2xl leading-8 tracking-wider">
          Unique hand- and ethically made clothing & homeware{' '}
        </span>
        <br /> from Budakeszi since 2016
      </p>
      <Link
        to={`/${language}/shop/women`}
        type="button"
        className="border border-white py-4 px-12 text-white text-xl uppercase hover:bg-green hover:border-green"
      >
        shop now
      </Link>
    </div>
  );
};

MainPageLogo.propTypes = {};

export default MainPageLogo;
