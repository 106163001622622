import React from 'react';
import PropTypes from 'prop-types';

const TransferData = (props) => {
  return (
    <div className="text-sm">
      <ul>
        <li>Név: Ivánfi Aliz EV.</li>
        <li>Bankszámlaszám: 11773425-05466278</li>
        <li>IBAN: HU39117734250546627800000000</li>
        <li>SWIFT: OTPVHUHB</li>
        <li>Közlemény: Megrendelés száma</li>
      </ul>
    </div>
  );
};

TransferData.propTypes = {};

export default TransferData;
