import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { generateCheckoutUrl, generateCategoryUrl } from './../../../shared/frontend-routes';

import { numberFormatter } from './../../../shared/helpers';

const OrderSummary = ({ cart, checkout }) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-lg pb-2">{t('order summary')}</h2>
      <div className="flex flex-row justify-between pb-1">
        <div>{t('subtotal')}:</div>
        <div> {numberFormatter(cart.totals.subtotal)} HUF</div>
      </div>
      <div className="flex flex-row justify-between pb-2 border-b border-black">
        <div>{t('shipping')}:</div>
        <div>{cart.totals.subtotal > 25000 ? 'Ingyenes' : numberFormatter(cart.totals.shipping_total) + ' HUF'}</div>
      </div>
      <div className="flex flex-row justify-between pt-2">
        <div>{t('total')}:</div>
        <div>
          {cart.totals.subtotal > 25000 ? numberFormatter(cart.totals.subtotal) : numberFormatter(cart.totals.total)}{' '}
          HUF
        </div>
      </div>
      {!checkout && (
        <div className="flex flex-col py-6 space-y-4">
          <Link to={generateCheckoutUrl('hu')} className="bg-green p-3 text-center uppercase text-white">
            {t('proceed to checkout')}
          </Link>
          <Link to={generateCategoryUrl('hu')} className="border border-green p-3 text-center uppercase text-green">
            {t('continue shopping')}
          </Link>
        </div>
      )}
      <div className="pt-4">
        <h2 className="text-lg pb-2">Fontos információ</h2>
        <p className="pb-1 text-sm">Homeware termékeinket készlethiány esetén 1-2 hét alatt készítjük el neked.</p>
        <p className="text-sm">
          Mivel minden darabot egyedileg a méreteidre szabunk, ezért az Elischer R ruhákat 2-3 hét alatt készítjük el
          neked.
        </p>
      </div>
    </>
  );
};

OrderSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  checkout: PropTypes.bool.isRequired,
};

export default OrderSummary;
