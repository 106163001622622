import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../shared/components/loader/Loader';

const MainPageCarouselItem = ({ item }) => {
  const [loading, setLoading] = useState(true);

  const handleLoading = () => {
    setLoading(false);
  };

  return (
    <div className="h-screen">
      {loading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      <img
        src={item['_embedded']['wp:featuredmedia'][0].media_details.sizes.full.source_url}
        onLoad={handleLoading}
        className={`h-screen lg:w-screen object-cover ${loading && 'hidden'}`}
      />
    </div>
  );
};

MainPageCarouselItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MainPageCarouselItem;
