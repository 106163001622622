import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: {
    product: undefined,
    variations: [],
  },
  error: false,
};

const productSlice = createGenericSlice({
  name: 'product',
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.loading = false;
      state.data.product = action.payload;
    },
    variationsSuccess: (state, action) => {
      state.loading = false;
      state.data.variations = action.payload;
    },
    reset: (state) => {
      state.error = false;
      state.data.product = undefined;
      state.data.variations = [];
    },
  },
});

const { actions, reducer } = productSlice;

const productState = (state) => state.product;

const mapVariations = (variations) =>
  variations.map((variation) => {
    const color = variation.attributes.find((a) => a.name === 'color');

    return {
      id: variation.id,
      color: color.option.substring(5, color.option.length - 3),
    };
  });

export const productSelector = createDraftSafeSelector(productState, (state) => ({
  product: state.data.product,
  variations: mapVariations(state.data.variations),
  loading: state.loading,
  error: state.error,
}));

export const { start, finishError, productSuccess, variationsSuccess, reset } = actions;

export default reducer;
