import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './../../../shared/components/accordion-item/AccordionItem';

const steps = [
  { key: 'product_handling', label: 'Kezelési útmutató' },
  { key: 'shipping_information', label: 'Szállítási információk' },
  { key: 'processing_time', label: 'Feldolgozási idő' },
  { key: 'guarantee', label: 'Garancia' },
];

const ProductInfoAccordion = ({ homeware }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleAccordionChange = (step) => {
    setCurrentStep(step);
  };

  return (
    <div className="">
      <AccordionItem
        step={0}
        label={steps[0].label}
        active={currentStep === 0}
        onAccordionClick={handleAccordionChange}
        openIcon={true}
      >
        <div className="py-2">
          A rendelésedet minden esetben beavatva küldjük, ami annyit jelent, hogy beavató mosással ellenőrizzük az anyag
          minőségét és az esetlegesen felmerülő hibákat. Darabjaink örök életre szólnak, ha megfelelően bánsz velük.
          Ehhez a következő kezelési útmutató nagy segítségedre lesz:
          <ul className="py-2">
            <li>Maximum 30ºC-on kímélő programon mosd! </li>
            <li>Maximum 150ºC-on vasald vagy gőzöld! </li>
            <li>Ne szárítsd szárítógépben! </li>
            <li>Ne alkalmazz fehérítést vagy vegytisztítást! </li>
          </ul>
        </div>
      </AccordionItem>
      <AccordionItem
        step={1}
        label={steps[1].label}
        active={currentStep === 1}
        onAccordionClick={handleAccordionChange}
        openIcon={true}
      >
        <div className="py-2">A szállítás 25.000 forint feletti vásárlás esetén ingyenes.</div>
      </AccordionItem>
      <AccordionItem
        step={2}
        label={steps[2].label}
        active={currentStep === 2}
        onAccordionClick={handleAccordionChange}
        openIcon={true}
      >
        <div className="py-2">
          {homeware
            ? 'Homeware termékeinket készlethiány esetén 1-2 hét alatt készítjük el neked.'
            : 'Mivel minden darabot egyedileg a méreteidre szabunk, ezért az Elischer R ruhákat 2-3 hét alatt készítjük el neked.'}
        </div>
      </AccordionItem>
      <AccordionItem
        step={3}
        label={steps[3].label}
        active={currentStep === 3}
        onAccordionClick={handleAccordionChange}
        openIcon={true}
      >
        <div className="py-2">
          Termékeinket mérethiba esetén azonnal kijavítjuk, emellett minden darabra örök garanciát vállalunk.
          <p className="py-2">
            Kérjük vedd figyelembe, hogy terméket visszavenni az üzletpolitikánkból adódóan nem áll módunkban.{' '}
            <a
              className="hover:underline"
              href="http://www.kozlonyok.hu/nkonline/mkpdf/hiteles/mk14030.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              45/2014 (II.26.) Korm. Rendelet 29. §. (1)
            </a>
          </p>
        </div>
      </AccordionItem>
    </div>
  );
};

ProductInfoAccordion.propTypes = {
  homeware: PropTypes.bool,
};

export default ProductInfoAccordion;
