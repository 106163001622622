import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useHistory } from 'react-router-dom';

import { AppContext } from './../../../app/AppContext';

import RoundLoader from './../../../../shared/components/round-loader/RoundLoader';

const transformPayer = (billing) => {
  return {
    email_address: billing.email,
    name: {
      surname: billing.last_name,
      given_name: billing.first_name,
    },
    /* 
needs some form tweaking first
phone: {
      country_code: '+36',
      national_number: '302522490',
    },
    address: {
      address_line_1: billing.address_1,
      address_line_2: billing.address_2,
      postal_code: billing.postcode,
      country_code: 'HU',
    }, */
  };
};

const PaypalContainer = ({ cart, order, onAddTransactionId, disabled }) => {
  const [{ isPending }] = usePayPalScriptReducer();
  const history = useHistory();
  const { language } = useContext(AppContext);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        payer: transformPayer(order.billing),
        purchase_units: [
          {
            description: 'Elischer-R megrendelés.',
            amount: {
              currency_code: 'HUF',
              value: cart.totals.total,
            },
            custom_id: order.id,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderID) => {
        onAddTransactionId(orderID);
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      console.log('successful payment');
      console.log(details);
      history.push(`/${language}/shop/checkout/success`);
    });
  };

  const onError = (data, actions) => {
    console.log(data);
    history.push(`/${language}/shop/checkout/failed`);
  };

  const onCancel = (data, actions) => {
    console.log('canceled', data);
  };

  return (
    <>
      {isPending ? <RoundLoader /> : null}
      <PayPalButtons
        className="w-80"
        style={{ layout: 'vertical', label: 'buynow', shape: 'rect', color: 'white' }}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
        onCancel={onCancel}
        disabled={disabled}
      />
    </>
  );
};

PaypalContainer.propTypes = {
  cart: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onAddTransactionId: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PaypalContainer;
