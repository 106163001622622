import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import RadioField from './../../../shared/components/radio-field/RadioField';

import { shippingMethodsSelector } from './../../../store/slices/shippingMethodsSlice';

import RoundLoader from './../../../shared/components/round-loader/RoundLoader';

const ShippingMethod = ({ loading, freeShipping, values }) => {
  const { t } = useTranslation();
  const { shippingMethods } = useSelector(shippingMethodsSelector);

  const fields = shippingMethods.map((method) => (
    <label
      key={method.method_id}
      className="border hover:border-green cursor-pointer p-8 w-full lg:w-64 flex items-center"
    >
      <div className="flex items-center">
        <div style={{ height: '20px', width: '20px' }}>
          <Field
            component={RadioField}
            name="shipping_method"
            value={method.method_id}
            disabled={
              (method.method_id !== 'free_shipping' && freeShipping) ||
              (method.method_id === 'free_shipping' && !freeShipping)
            }
            checked={values.shipping_method === method.method_id}
          />
        </div>
        <div className="pl-4 flex flex-col">
          <p className="text-lg pb-2">{t(method.method_title)}</p>
          <div className="text-xs">{t(`${method.method_id}_description`)}</div>
        </div>
      </div>
    </label>
  ));

  return (
    <div className="w-full pt-8">
      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">{fields}</div>
      <div className="pt-4">
        <button
          type="submit"
          className="border border-green py-4 px-6 flex items-center justify-center w-full lg:w-60 text-green hover:bg-green hover:text-white"
          disabled={loading}
        >
          {loading ? <RoundLoader /> : t('next')}
        </button>
      </div>
    </div>
  );
};

ShippingMethod.propTypes = {
  loading: PropTypes.bool,
  freeShipping: PropTypes.bool,
  values: PropTypes.object,
};

export default ShippingMethod;
