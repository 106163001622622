import { combineReducers } from '@reduxjs/toolkit';

import slider from './../slices/sliderSlice';
import page from './../slices/pageSlice';
import shopCategories from './../slices/shopCategoriesSlice';
import products from './../slices/productsSlice';
import product from './../slices/productSlice';
import cart from './../slices/cartSlice';
import colors from './../slices/colorsSlice';
import order from './../slices/orderSlice';
import user from './../slices/userSlice';
import shippingMethods from './../slices/shippingMethodsSlice';
import mail from './../slices/mailSlice';
import modal from './../slices/modalSlice';
import customerOrders from './../slices/customerOrdersSlice';

const rootReducer = combineReducers({
  slider,
  shopCategories,
  products,
  product,
  cart,
  colors,
  order,
  user,
  shippingMethods,
  page,
  mail,
  modal,
  customerOrders,
});

export default rootReducer;
