import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TransferData from './../transfer-data/TransferData';
import Address from './../address/Address';
import CheckoutCartList from './../checkout-cart-list/CheckoutCartList';
import RoundLoader from './../../../../shared/components/round-loader/RoundLoader';
import PaypalContainer from './../paypal-container/PaypalContainer';

import { formFields } from './../checkout/formHelpers';

const OrderReview = ({ values, cart, onPlaceOrder, loading, shippingMethods, order, onAddTransactionId }) => {
  const { t } = useTranslation();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const acceptTerms = () => {
    setAcceptedTerms((prev) => !prev);
  };

  const shippingMethod = shippingMethods.find((item) => item.method_id === values.shipping_method);

  return (
    <div className="pt-4">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <h3 className="text-xl py-3">{t('billing address')}</h3>
          <Address fields={formFields.billing} values={values.billing} />
        </div>
        <div>
          <h3 className="text-xl py-3">{t('shipping address')}</h3>
          <Address fields={formFields.shipping} values={values.same_address ? values.billing : values.shipping} />
        </div>
      </div>
      <h3 className="text-xl py-3">{t('payment')}</h3>
      <div>
        {values.payment_method === 'bacs' && (
          <>
            <div className="pb-2">Fizetés közvetlenül a bankszámlánkra.</div>
            <TransferData />
          </>
        )}
        {values.payment_method === 'ppcp-gateway' && (
          <div>
            <div className="text-sm pb-2">
              {t('Credit or Debit Card')} {t('with paypal')}.
            </div>
            <table border="0" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td align="center">
                    <a
                      href="https://www.paypal.com/webapps/mpp/paypal-popup"
                      title="How PayPal Works"
                      onClick={() => {
                        window.open(
                          'https://www.paypal.com/webapps/mpp/paypal-popup',
                          'WIPaypal',
                          'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'
                        );
                        return false;
                      }}
                    >
                      <img
                        src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
                        border="0"
                        alt="PayPal Logo"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {/* {values.payment_method === 'paypal' && <div>paypal</div>} */}
      </div>
      <h3 className="text-xl py-3">{t('shipping_method')}</h3>
      <div className="pb-3">
        {t(shippingMethod.method_title)}:{' '}
        <span className="text-sm">{t(`${shippingMethod.method_id}_description`)}</span>
      </div>
      <h3 className="text-xl py-3">{t('items')}</h3>
      <CheckoutCartList items={cart.items} />
      <div className="py-6">
        <label className="flex space-x-2 items-center justify-center">
          <div style={{ height: '20px', width: '20px' }}>
            <input
              style={{ height: '20px', width: '20px' }}
              type="checkbox"
              name="terms and conditions"
              value={acceptedTerms}
              onChange={acceptTerms}
              className="mr-2"
            />
          </div>
          <div>
            Tudomásul veszem és elfogadom az általános szerződési feltételeket (ASZF), és az adatkezelési nyilatkozatot,
            valamint a rendeléssel járó fizetési kötelezettséget.
          </div>
        </label>
      </div>
      <div className="flex justify-center flex-col items-center">
        {order.payment_method === 'ppcp-gateway' && (
          <PaypalContainer
            cart={cart}
            order={order}
            onAddTransactionId={onAddTransactionId}
            disabled={!acceptedTerms}
          />
        )}
        {order.payment_method === 'bacs' && (
          <button
            type="button"
            className="border border-green py-4 px-6 text-green hover:bg-green hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white disabled:text-green w-full lg:w-60"
            onClick={onPlaceOrder}
            disabled={!acceptedTerms}
          >
            {loading ? <RoundLoader /> : t('finalizie order')}
          </button>
        )}
      </div>
    </div>
  );
};

OrderReview.propTypes = {
  values: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  onPlaceOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  shippingMethods: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,
  onAddTransactionId: PropTypes.func.isRequired,
};

export default OrderReview;
